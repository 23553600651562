import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  salesConfig: [],
  saleConfigValue: {}
};

const sales = createSlice({
  name: 'salesConfig',
  initialState,
  reducers: {
    setSalesConfig: (state, action) => {
      state.salesConfig = action.payload;
    },
    setSaleConfig: (state, action) => {
      const { id } = action.payload;
      state.saleConfigValue = {
        ...state.saleConfigValue,
        [id]: action.payload
      };
    }
  }
});

export default sales.reducer;
export const { setSalesConfig, setSaleConfig } = sales.actions;
