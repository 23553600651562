import { useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { DownOutlined, GlobalOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Dropdown, Flex, Layout, Menu, Space } from 'antd';
import { isEmpty } from 'lodash';
import { getPascalCaseText } from 'utils/utility';

import { HOME, LANGUAGES } from 'shared/constants';
import { uesAntdToken, useBaseState, useI18n, useTranslation } from 'shared/hooks';

import { SolBiLogo } from 'components/common';

import UserProfile from './UserProfile';
import UserSelection from './UserSelection';

const { Header, Sider, Content } = Layout;

const languages = Object.keys(LANGUAGES).map((key) => ({ label: LANGUAGES[key], key }));
const englishLang = LANGUAGES['en-US'].toLowerCase();

const MainLayout = (props) => {
  const { getTValue, i18n } = useI18n();
  const {
    token: { colorBgContainer, borderRadiusLG, colorBorder, colorBgLayout }
  } = uesAntdToken();

  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const { authUser, authPermission } = useBaseState();
  const authLang = authUser?.lang;
  const isOtherAuthLang = authLang !== englishLang;
  const currentSelectedLang = localStorage.getItem('i18OtherLang') || authLang;
  const { getLanguagesAndSelected } = useTranslation({
    authUserLang: currentSelectedLang,
    isUserSelectedEnglishLang: currentSelectedLang === englishLang
  });
  const otherLangMenus = getTValue('dashboard') || [];
  const visibleLanguages = [englishLang, authLang].map((el) => el?.split('-')?.[0].toLowerCase());

  const FILTER_MENUS = useMemo(() => {
    if (!authPermission || isEmpty(authPermission)) return [];
    return authPermission
      .filter((el) => el.is_visible_in_sidebar)
      .map((el, i) => ({
        key: el.id,
        id: el.id,
        path: el.module_name,
        icon: el?.icon || '',
        label: otherLangMenus[el.module_name] || getPascalCaseText(el.module_name)
      }));
  }, [authPermission?.length, otherLangMenus.length]);

  const handleMenuClick = (menu) => {
    const currentObj = FILTER_MENUS.find((el) => el.key === menu.key);
    navigate(currentObj.path);
  };

  const languageList = useMemo(() => languages.filter((el) => visibleLanguages.includes(el.label.toLowerCase())), []);

  const selectedLang = languageList.find((el) => currentSelectedLang?.toLowerCase()?.includes(el.label.toLowerCase()));

  useEffect(() => {
    if (selectedLang) {
      i18n.changeLanguage(selectedLang.key);
    }
  }, [selectedLang?.key]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          padding: '0px 16px 0px 16px',
          background: colorBgContainer,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${colorBorder}`,
          borderBottomWidth: `calc(100% - 500px)`
        }}
      >
        <Flex align="center">
          {/* <Button onClick={() => setCollapsed(!collapsed)} type="text">
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button> */}
          <SolBiLogo onClick={() => navigate(HOME)} />
        </Flex>
        <UserSelection />
        <Space>
          {isOtherAuthLang && (
            <Dropdown
              overlayClassName="notranslate"
              menu={{
                items: languageList,
                onClick: (lang) => {
                  i18n.changeLanguage(lang.key);
                  const label = languageList.find((el) => el.key === lang.key)?.label?.toLowerCase();
                  localStorage.setItem('i18OtherLang', label);
                  // isOtherAuthLang && getLanguagesAndSelected && getLanguagesAndSelected(label);
                  isOtherAuthLang && window.location.reload(true);
                },
                ...(selectedLang && {
                  selectable: true,
                  defaultSelectedKeys: [selectedLang.key]
                })
              }}
            >
              <Button type="text" className="notranslate">
                <Space>
                  {LANGUAGES[i18n.language]}
                  <GlobalOutlined />
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          )}
          <UserProfile />
        </Space>
      </Header>

      <Layout style={{ background: colorBgLayout }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            background: colorBgContainer,
            borderRight: `1px solid ${colorBorder}`
          }}
        >
          <Menu defaultSelectedKeys={['1']} items={FILTER_MENUS} onClick={handleMenuClick} style={{ border: 0 }} />
        </Sider>

        <Content
          style={{
            margin: 16,
            padding: 24,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            border: `1px solid ${colorBorder}`
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
