// types
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'store/index';
import { decodeToken } from 'utils/apiUtils';

import { CHANGE_PASSWORD_URL, FORGOT_PASSWORD_URL, LOGIN_URL, RESET_PASSWORD_URL, UPLOAD_IMAGE } from 'shared/constants';

import { openErrorMessage, openSuccessMessage } from './toast';
import { onSelectedUser, onSetAuthUser } from './user';

// initial state
const initialState = {
  userDetails: {},
  loading: false,
  isLoggedIn: false
};

export const Login = createAsyncThunk('auth/Login', async (data, { dispatch, rejectWithValue }) => {
  try {
    // Assuming the server expects { email, password }
    const response = await fetch(LOGIN_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: data.email, password: data.password })
    }).then((res) => res.json());

    if (response.code === 400) {
      const errorMessage = response.message || 'Invalid email or password. Please try again.';
      dispatch(openErrorMessage({ message: errorMessage }));
      return rejectWithValue(errorMessage);
    } else if (response.code !== 200) {
      const errorMessage = response.message || 'Login failed. Please try again.';
      dispatch(openErrorMessage({ message: errorMessage }));
      return rejectWithValue(errorMessage);
    }

    let token = response?.data[0]?.token;

    localStorage.setItem('token', token);

    const decodedToken = token ? decodeToken(token) : null;
    localStorage.setItem('company_id', decodedToken?.comp_id);
    localStorage.setItem('role', decodedToken?.role);
    if (decodedToken) {
      dispatch(onSetAuthUser({ authUser: decodedToken }));
      localStorage.setItem('authUser', JSON.stringify(decodedToken));
      if (decodedToken.role.toLocaleLowerCase() !== 'admin') {
        dispatch(onSelectedUser({ selectedUser: decodedToken }));
        localStorage.setItem('selectedUser', JSON.stringify(decodedToken));
      }
    }
    dispatch(openSuccessMessage({ message: 'Login successful!' }));
    return response;
  } catch (error) {
    console.error(error);
    return rejectWithValue('An unexpected error occurred. Please try again.');
  }
});

export const ForgotPassword = createAsyncThunk('auth/ChangePassword', async (data, { dispatch, getState }) => {
  try {
    const response = await fetch(FORGOT_PASSWORD_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .catch((err) => err);
    if (response.errors) {
      dispatch(openErrorMessage({ message: response.errors[0].message }));
    } else if (response?.status !== 200) {
      dispatch(openErrorMessage({ message: response.msg }));
    }
    dispatch(openSuccessMessage({ message: response.msg }));
    return response;
  } catch (error) {
    console.error(error);
  }
});

export const ResetPassword = createAsyncThunk('auth/ChangePassword', async (data, { dispatch, getState }) => {
  try {
    const response = await fetch(RESET_PASSWORD_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .catch((err) => err);
    if (response.errors) {
      dispatch(openErrorMessage({ message: response.errors[0].message }));
      return response.errors;
    } else if (response?.code !== 200) {
      dispatch(openErrorMessage({ message: response.msg }));
      return response.data;
    }
    dispatch(openSuccessMessage({ message: response.msg }));
    return response.data;
  } catch (error) {
    console.log(eror);
  }
});

export const ChangePassword = createAsyncThunk('auth/ChangePassword', async (data, { dispatch, getState }) => {
  console.log(data);
  const response = await fetch(CHANGE_PASSWORD_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` },
    body: JSON.stringify(data)
  }).then((res) => res.json());
  dispatch(openSuccessMessage({ message: response.msg }));

  return response.data;
});

export const UploadImage = createAsyncThunk('auth/UploadImage', async (data, { dispatch, getState }) => {
  const response = await fetch(UPLOAD_IMAGE, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Accept: '*/*', Authorization: `Bearer ${localStorage.getItem('token')}` },
    body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .catch((err) => err);
  if (response.errors) {
    dispatch(openErrorMessage({ message: response.errors[0].message }));
    return;
  } else if (response?.code !== 200) {
    dispatch(openErrorMessage({ message: response.msg }));
    return;
  }
  dispatch(openSuccessMessage({ message: response.msg }));
  return response;
});

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action) {
      state.userDetails = action.payload;
    }
  },
  extraReducers: {
    [ChangePassword.pending]: (state, action) => {
      state.loading = true;
    },
    [ChangePassword.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [ChangePassword.rejected]: (state, action) => {
      state.loading = false;
    },
    [UploadImage.pending]: (state, action) => {
      state.loading = true;
    },
    [UploadImage.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [UploadImage.rejected]: (state, action) => {
      state.loading = false;
    },
    [ForgotPassword.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [Login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.loading = false;
    }
  }
});

export default auth.reducer;

export const { setUser, setIsLoggedIn } = auth.actions;
