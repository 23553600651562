import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Flex, Select } from 'antd';
import { isEmpty, sortBy } from 'lodash';
import { onClearSelectedUser, onClearSelectedUserName, onClearUserState, onSelectedUser, onSelectedUserName } from 'store/reducers/user';
import { getUserRoleDataPayload } from 'utils/api.bodies';
import { decodeToken } from 'utils/apiUtils';
import { httpRequest } from 'utils/utility';

import { GET_SELECT_DATA_URL, HOME, POST, USER_ROLE, USER_TOKEN_URL } from 'shared/constants';
import { useBaseState } from 'shared/hooks';

const UserSelection = (props) => {
  const { selectedUserName, selectedUser, authUser } = useBaseState();
  const authCompanyId = authUser?.comp_id;
  const currentSelectedUser = localStorage.getItem('selectedUserName');

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [userRoleData, setUserRoleData] = useState([]);
  const [userRoleValue, setUserRoleValue] = useState(selectedUserName || undefined);
  const authRole = authUser?.role;
  const isAdmin = authRole === USER_ROLE.SUPER_ADMIN;
  const isHomePage = pathname.split('/')[1] === HOME;

  useEffect(() => {
    if (isAdmin) {
      getUserRoleData();
    }

    return () => dispatch(onClearUserState());
  }, []);

  const getUserRoleData = async () => {
    const {
      response: { data: userRoleData, code },
      error
    } = await httpRequest(`${GET_SELECT_DATA_URL}`, getUserRoleDataPayload(), POST, true);
    if (code === 200) {
      if (!isEmpty(userRoleData)) {
        let userList = sortBy(userRoleData, ['company_id']);
        const selectedCompanyIdOneUser = userList?.find((el) => el?.company_id === authCompanyId)?.email;

        if (selectedCompanyIdOneUser && !selectedUser?.email) {
          fetchToken(selectedCompanyIdOneUser);
        }

        userList = userRoleData.map((el) => ({ label: el.name, value: el.email, company_id: el.company_id }));
        setUserRoleData(userList);
      } else {
        setUserRoleData([]);
      }
    }
  };

  const fetchToken = async (email) => {
    const {
      response: { data, code },
      error
    } = await httpRequest(USER_TOKEN_URL, JSON.stringify({ email }), POST, true);
    if (code === 200) {
      const token = data[0]?.token || '';

      const decodedToken = token ? decodeToken(token) : null;
      localStorage.setItem(`company_token`, data[0]?.token);
      localStorage.setItem(`get_comp_role`, decodedToken?.role);
      localStorage.setItem(`get_comp_id`, decodedToken?.comp_id);
      decodeToken && dispatch(onSelectedUser({ selectedUser: decodedToken }));
      decodeToken && localStorage.setItem('selectedUser', JSON.stringify(decodedToken));
    }
  };

  const handleClearSelectedUserData = () => {
    localStorage.removeItem(`company_token`);
    localStorage.removeItem(`get_comp_role`);
    localStorage.removeItem(`get_comp_id`);
    localStorage.removeItem(`selectedUser`);
    localStorage.removeItem(`selectedUserName`);
  };

  const handleClear = () => {
    setUserRoleValue(undefined);
    dispatch(onClearSelectedUser());
    dispatch(onClearSelectedUserName());
    handleClearSelectedUserData();
    fetchToken(userRoleData?.find((el) => el?.company_id === authCompanyId)?.value);
  };
  const handleSelect = (_, options) => {
    const { label, value } = options;

    if (value) {
      setUserRoleValue(value);
      dispatch(onSelectedUserName({ selectedUserName: label }));
      localStorage.setItem('selectedUserName', label);
      fetchToken(value);
    }
  };

  if (!isAdmin) return null;

  return (
    <>
      {isHomePage ? (
        <Select
          style={{ width: 200 }}
          options={userRoleData}
          onSelect={handleSelect}
          value={userRoleValue}
          placeholder="Select User"
          allowClear
          onClear={handleClear}
        />
      ) : (
        <>
          {currentSelectedUser ? (
            <Flex align="center" style={{ borderBottom: '2px solid #d46b08' }}>
              {currentSelectedUser}
            </Flex>
          ) : null}
        </>
      )}
    </>
  );
};

export default UserSelection;
