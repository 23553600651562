// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  isOpen: false,
  message: '',
  severity: ''
};

// ==============================|| SLICE - MENU ||============================== //

const toast = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    openSuccessMessage(state, action) {
      state.isOpen = true;
      state.message = action.payload.message;
      state.severity = 'success';
    },
    openErrorMessage(state, action) {
      state.isOpen = true;
      state.message = action.payload.message;
      state.severity = 'error';
    },
    closeToast(state) {
      state.isOpen = false;
    }
  }
});

export default toast.reducer;

export const { openSuccessMessage, openErrorMessage, closeToast } = toast.actions;
