// third-party
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

// project import
import reducers from './reducers';
import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
  reducer: reducers,
  applyMiddleware: applyMiddleware(thunk)
});

const { dispatch } = store;

export { store, dispatch };
