import { useEffect, useState } from 'react';

const langKey = 'glang';
const scriptURL = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';

const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname}`;
};

const removeElementsBySelectors = (selectors) => {
  selectors.forEach((selector) => {
    const elements = document.querySelectorAll(selector);
    elements.forEach((el) => el.remove());
  });
};

const removeScriptsBySrc = (srcPatterns) => {
  srcPatterns.forEach((pattern) => {
    const script = document.querySelector(`script[src*="${pattern}"]`);
    if (script?.parentNode) {
      script.parentNode.removeChild(script);
    }
  });
};

export const useTranslation = (props) => {
  const { authUserLang, isUserSelectedEnglishLang } = props;
  const [languages, setLanguages] = useState([]);
  const [selectedLang, setSelectedLang] = useState(authUserLang);
  // console.log('selectedLang', selectedLang, authUserLang, isUserSelectedEnglishLang);

  const handleRemoveScript = () => {
    removeScriptsBySrc(['translate.google.com', 'translate.googleapis.com', 'gstatic.com']);
    removeElementsBySelectors(['.skiptranslate', '.VIpgJd-ZVi9od-aZ2wEe-wOHMyf', '.VIpgJd-ZVi9od-xl07Ob-OEVmcd', '#goog-gt-tt']);
    deleteCookie('googtrans');
    localStorage.removeItem(langKey);
  };

  const handleAddScript = () => {
    const script = document.createElement('script');
    script.src = scriptURL;
    script.async = true;
    document.body.appendChild(script);

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          includedLanguages: 'en,pt,nl',
          autoDisplay: false,
          multilanguagePage: false
        },
        'google_translate_element'
      );
    };

    const gScript = document.createElement('script');
    gScript.id = 'gScript';
    gScript.type = 'text/javascript';
    gScript.text = `(function () {
      const xhrData = new WeakMap();

      const originalXhrOpen = XMLHttpRequest.prototype.open;
      const originalXhrSetRequestHeader = XMLHttpRequest.prototype.setRequestHeader;
      const originalXhrSend = XMLHttpRequest.prototype.send;

      XMLHttpRequest.prototype.open = function (method, url, async, username, password) {
        xhrData.set(this, { method, url: typeof url === 'string' ? url : url.toString(), headers: {} });
        originalXhrOpen.apply(this, arguments);
      };

      XMLHttpRequest.prototype.setRequestHeader = function (name, value) {
        const data = xhrData.get(this);
        if (data) {
          data.headers[name] = value;
        }
        originalXhrSetRequestHeader.apply(this, arguments);
      };

      XMLHttpRequest.prototype.send = function (body) {
        this.addEventListener('load', function () {
          const data = xhrData.get(this);
          if (data && data.url.includes('https://translate-pa.googleapis.com/v1/translateHtml')) {
            localStorage.setItem('gtck', data?.headers?.['X-goog-api-key']);
          }
        });

        this.addEventListener('error', function () {
          console.error('XMLHttpRequest error:', this.statusText);
        });

        originalXhrSend.apply(this, arguments);
      };
    })()`;

    document.head.appendChild(gScript);
  };

  const getExtractString = (str) => str?.replace(/[^a-zA-Z]/g, '')?.toLowerCase();

  const getLanguagesAndSelected = (label) => {
    // console.log('label>>>>>>', label);
    const translateElement = document.querySelector('.skiptranslate');
    // console.log('translateElement', translateElement);
    if (!translateElement) return;

    const select = document.querySelector('select.goog-te-combo');
    // console.log('select', select);
    if (!select) return;

    const options = Array.from(select.options);
    const langs = options
      .map((option) => ({
        code: option.value,
        name: option.text
      }))
      .filter((el) => el.code);

    if (!langs.some((lang) => lang.code === 'en')) {
      langs.push({ code: 'en', name: 'English' });
    }

    setLanguages(langs);

    const selectedItem = langs.find((el) => getExtractString(el.name).includes(getExtractString(label)));
    // console.log('selectedItem', selectedItem, langs.length);
    if (selectedItem) {
      // console.log('selectedItem', selectedItem, select);
      setSelectedLang(selectedItem.name?.toLowerCase());
      localStorage.setItem(langKey, selectedItem.code);
      select.value = selectedItem.code;
      select.dispatchEvent(new Event('change'));
    }
  };

  useEffect(() => {
    if (!isUserSelectedEnglishLang) {
      handleRemoveScript();
      handleAddScript();
    }
    return () => {
      if (!isUserSelectedEnglishLang) {
        handleRemoveScript();
      }
    };
  }, []);

  useEffect(() => {
    if (isUserSelectedEnglishLang) return;
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const translateElement = document.querySelector('.skiptranslate');
          if (translateElement) {
            // console.log('translateElement found through MutationObserver');
            observer.disconnect(); // Stop observing once the element is found
            getLanguagesAndSelected(selectedLang); // Now you can call the function to extract languages
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);
  // }, [selectedLang]);

  useEffect(() => {
    if (isUserSelectedEnglishLang) return;
    const retryInterval = setInterval(() => {
      const translateElement = document.querySelector('.skiptranslate');
      if (translateElement) {
        // console.log('translateElement found after retry');
        clearInterval(retryInterval);

        getLanguagesAndSelected(selectedLang);
      }
    }, 1500);
    return () => clearInterval(retryInterval);
  }, []);

  if (isUserSelectedEnglishLang) {
    return {
      languages: [],
      getLanguagesAndSelected: null
    };
  }

  return {
    languages,
    getLanguagesAndSelected
  };
};
