import { useCallback, useState } from 'react';

import { isEmpty } from 'lodash';
import { getDeleteApiURL, httpRequest } from 'utils/utility';

import { POST } from 'shared/constants';
import { openSuccessMessage } from 'shared/utils';

const PAGE_SIZE = 100; // Global default

export const useScrollPagination = (apiUrl, payload, apiMethod = POST, limit = PAGE_SIZE) => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [offset, setOffset] = useState(0);

  const [deleteRow, setDeleteRow] = useState(null);

  const onScroll = useCallback(
    (e) => {
      const isScrollbarBottom = Math.abs(e.target?.scrollHeight - e.target?.scrollTop - e.target?.clientHeight) <= 1;
      if (isScrollbarBottom && hasMoreData) {
        fetchData();
      }
    },
    [offset]
  );

  const fetchData = async (customPayload = {}) => {
    setIsLoading(true);
    const {
      response: { data: newData, code },
      error
    } = await httpRequest(apiUrl, JSON.stringify({ ...payload, limit: limit, offset: offset, ...customPayload }));
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    if (code === 200) {
      setIsLoading(false);
      setRows((prevData) => [...prevData, ...newData]);
      setOffset((prevOffset) => prevOffset + limit);
      setHasMoreData(isEmpty(newData) ? false : true);
    }
    if (error) {
      setHasMoreData(false);
      setIsLoading(false);
      console.error('Error fetching :', error);
    }
  };

  const onDelete = async (companyId, payload) => {
    const {
      response: { code },
      error
    } = await httpRequest(getDeleteApiURL(companyId), payload);
    if (code === 200) {
      setRows(rows.filter((el) => el.id !== deleteRow?.id));
      openSuccessMessage({ message: `Deleted successfully!` });
      fetchData();
      setDeleteRow(null);
    }
  };

  return {
    rows,
    isLoading,
    fetchData,
    setRows,
    onDelete,
    setDeleteRow,
    deleteRow,
    onScroll
  };
};
