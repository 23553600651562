import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Result } from 'antd';

import { HOME } from 'shared/constants';

export const Unauthorize = () => {
  const navigate = useNavigate();
  return (
    <Result
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" onClick={() => navigate('/' + HOME)}>
          Back Home
        </Button>
      }
    />
  );
};
