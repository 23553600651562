// third-party
import { combineReducers } from 'redux';

import auth from './auth';
// project import
import menu from './menu';
import sales from './sales';
import toast from './toast';
import user from './user';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, toast, auth, sales, user });

export default reducers;
