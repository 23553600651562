import React from 'react';

import { Alert, Button, Col, Flex, Row, Typography } from 'antd';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by Error Boundary:', error);
    console.error('Error details:', errorInfo);

    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Row justify="center" align="middle" style={{ height: '100vh', margin: 0 }}>
          <Col>
            <Alert
              message={
                <Typography.Title level={5} style={{ margin: 0 }}>
                  Something went wrong
                </Typography.Title>
              }
              description={
                <>
                  <summary>We encountered an unexpected error. Please try again later.</summary>

                  <details>
                    <summary>Click for more details</summary>
                    <pre>{this.state.error && this.state.error.toString()}</pre>
                    <pre>{this.state.errorInfo && this.state.errorInfo.componentStack}</pre>
                  </details>
                </>
              }
              type="error"
              showIcon
              style={{ marginBottom: '16px' }}
            />
            <Flex justify="flex-end">
              <Button type="primary" onClick={() => window.location.reload()}>
                Reload Page
              </Button>
            </Flex>
          </Col>
        </Row>
      );
    }

    return this.props.children;
  }
}
