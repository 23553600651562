import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import DOMPurify from 'dompurify';

const defaultModules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ align: [] }],
    ['link', 'image'],
    ['clean'] // Remove formatting button
  ]
};

const defaultFormats = [
  'font',
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'color',
  'background',
  'script',
  'list',
  'bullet',
  'indent',
  'align',
  'link',
  'image'
];

export const RichEditor = (props) => {
  const { modules = defaultModules, formats = defaultFormats, isVisibleEditor = true, isVisiblePreview = false, ...rest } = props;

  // console.log('value', props.value);

  const decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  const rawHtml = decodeHtml(props?.value);
  // console.log('rawHtml', rawHtml);
  const sanitizedHtml = DOMPurify.sanitize(rawHtml);
  // console.log('sanitizedHtml', sanitizedHtml);
  return (
    <>
      {isVisibleEditor && <ReactQuill theme="snow" modules={modules} formats={formats} {...rest} />}
      {isVisiblePreview && <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />}
    </>
  );
};
