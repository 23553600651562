import { blue, gold, green, red } from '@ant-design/colors';
import { App } from 'antd';
import { startCase } from 'lodash';

export const TOAST_TYPE = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

const { SUCCESS, INFO, WARNING, ERROR } = TOAST_TYPE;

const COLOR = {
  [SUCCESS]: {
    border: green[2],
    borderLeft: green[5],
    backgroundColor: green[0]
  },
  [INFO]: {
    border: blue[2],
    borderLeft: blue[5],
    backgroundColor: blue[0]
  },
  [WARNING]: {
    border: gold[2],
    borderLeft: gold[5],
    backgroundColor: gold[0]
  },
  [ERROR]: {
    border: red[2],
    borderLeft: red[4],
    backgroundColor: red[0]
  }
};

let openSuccessMessage, openInfoMessage, openWarningMessage, openErrorMessage;

export const Toast = () => {
  const { notification } = App.useApp();
  const openNotification = (type, message) =>
    notification.open({
      type,
      message: <b>{startCase(type)}</b>,
      description: message,
      style: {
        // border: `1px solid ${COLOR[type].border}`,
        borderLeft: `7px solid ${COLOR[type].borderLeft}`,
        borderRadius: 8,
        backgroundColor: `${COLOR[type].backgroundColor}`
      }
    });

  const onSuccess = ({ message }) => openNotification(SUCCESS, message);

  const onInfo = ({ message }) => openNotification(INFO, message);

  const onWarning = ({ message }) => openNotification(WARNING, message);

  const onError = ({ message }) => openNotification(ERROR, message);

  openSuccessMessage = onSuccess;
  openInfoMessage = onInfo;
  openWarningMessage = onWarning;
  openErrorMessage = onError;

  return null;
};

export { openErrorMessage, openInfoMessage, openWarningMessage, openSuccessMessage };
