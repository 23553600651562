import { useTranslation } from 'react-i18next';

import { LANGUAGES } from 'shared/constants';

export const useI18n = () => {
  const { i18n, t } = useTranslation();

  const isOtherLangSelected = i18n.language !== Object.keys(LANGUAGES)[0]; // English lang is not selected!

  const getTValue = (key, opts = undefined) => t(key, opts ? opts : { returnObjects: true });

  return {
    t,
    i18n,
    isOtherLangSelected,
    getTValue
  };
};
