const dbName = 'configDB';
const storeName = 'configStore';

let db = window.indexedDB;

const open = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: 'id' });
      }
    };

    request.onsuccess = (event) => {
      db = event.target.result;
      resolve(db);
    };

    request.onerror = (event) => {
      reject('Error opening database');
    };
  });
};

const close = () => {
  const request = indexedDB.deleteDatabase(dbName);

  request.onsuccess = () => {
    console.log(`Database ${dbName} deleted successfully.`);
  };

  request.onerror = (event) => {
    console.error('Error deleting database:', event.target.errorCode);
  };

  request.onblocked = () => {
    console.warn('Database deletion blocked. Close all open connections to the database.');
  };
};

const add = async (data) => {
  await open();
  const transaction = db.transaction(storeName, 'readwrite');
  const store = transaction.objectStore(storeName);
  store.add(data);
};

const get = async (id) => {
  await open();
  const transaction = db.transaction(storeName, 'readonly');
  const store = transaction.objectStore(storeName);
  const request = store.get(id);
  return new Promise((resolve, reject) => {
    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
    request.onerror = (event) => {
      reject('Error retrieving data' + event.target.error);
    };
  });
};

export const indexDB = {
  add,
  get,
  // delete,
  open,
  close
};
