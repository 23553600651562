import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Form } from 'antd';
import { camelCase, startCase } from 'lodash';
import {
  getAllUsersRolesPayload,
  getCompanyListPayload,
  getDashboardListPayload,
  getSelectedUserSchemaPayload,
  getSelectedUserTablePayload
} from 'utils/api.bodies';
import { getServiceApiURL, httpRequest } from 'utils/utility';

import { GET_SELECT_DATA_URL, TABLES_SELECT } from 'shared/constants';

export const useBaseState = (props = {}) => {
  const {
    shouldCallCompanyListApi = false,
    shouldCallDashboardListApi = false,
    shouldCallTablesListApi = false,
    shouldCallRolesListApi = false,
    currentTableURL = ''
  } = props;

  const [form] = Form.useForm();
  const formData = Form.useWatch([], form);

  const navigate = useNavigate();
  const params = useParams();
  const upsertId = params?.id;

  const dispatch = useDispatch();
  const { selectedUser, authUser, selectedUserName, permission, featureFlagsPermission } = useSelector((state) => state.user);
  // console.log('permission', permission, currentTableURL);
  const authPermission = currentTableURL ? permission?.find((el) => el.module_name === currentTableURL) : permission;
  // console.log('authPermission', authPermission);
  const companyId = selectedUser?.comp_id;
  const role = selectedUser?.role;
  const authRole = authUser?.role;

  const [companyList, setCompanyList] = useState([]);
  const [dashboardList, setDashboardList] = useState([]);
  const [tables, setTables] = useState([]);
  const [databaseName, setDatabaseName] = useState('');
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    if (shouldCallCompanyListApi && companyId) {
      const getCompanyList = async () => {
        const {
          response: { code, data = [] },
          error
        } = await httpRequest(GET_SELECT_DATA_URL, getCompanyListPayload());
        if (code === 200) {
          setCompanyList(data);
        }
      };
      getCompanyList();
    }
  }, [shouldCallCompanyListApi, companyId]);

  useEffect(() => {
    if (shouldCallDashboardListApi) {
      const getDashboardsList = async () => {
        const {
          response: { code, data = [] },
          error
        } = await httpRequest(getServiceApiURL(companyId, true), getDashboardListPayload(role));
        if (code === 200) {
          setDashboardList(data.map((el) => ({ ...el, id: el?.id?.toString() })));
        }
      };
      getDashboardsList();
    }
  }, [shouldCallDashboardListApi]);

  useEffect(() => {
    if (shouldCallTablesListApi) {
      const getSelectedUserSchema = async () => {
        const {
          response: { code, data },
          error
        } = await httpRequest(GET_SELECT_DATA_URL, getSelectedUserSchemaPayload(companyId));
        if (code === 200) {
          const database = data?.[0]?.path?.split('/')?.[0];
          if (database) {
            setDatabaseName(database);
            getSelectedUserTables(database);
          }
        }
      };

      const getSelectedUserTables = async (databaseName) => {
        const {
          response: { code, data = [] },
          error
        } = await httpRequest(TABLES_SELECT, getSelectedUserTablePayload(databaseName));
        // if (code === 200) {
        const table = [...new Set(data[0]?.tables?.flat())]
          .map((el) => ({
            label: startCase(camelCase(el?.replace(/_/g, ' '))),
            value: el
          }))
          ?.sort((a, b) => a?.label?.localeCompare(b?.label));
        setTables(table);
        // }
      };

      getSelectedUserSchema();
    }
  }, [shouldCallTablesListApi]);

  useEffect(() => {
    if (shouldCallRolesListApi) {
      const getAllUserRoles = async () => {
        const {
          response: { code, data },
          error
        } = await httpRequest(GET_SELECT_DATA_URL, getAllUsersRolesPayload(companyId));
        const roles = data?.map((el) => ({ label: el.role, value: el.role })) || [];
        setUserRoles(roles);
      };
      getAllUserRoles();
    }
  }, [shouldCallRolesListApi, companyId]);

  return {
    form,
    formData,
    upsertId,
    selectedUser,
    authUser,
    selectedUserName,
    companyId,
    role,
    navigate,
    params,
    dispatch,
    authRole,
    companyList,
    dashboardList,
    permission,
    authPermission,
    tables,
    databaseName,
    userRoles,
    featureFlagsPermission
  };
};
