import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedUser: JSON.parse(localStorage.getItem('selectedUser')) || '',
  authUser: JSON.parse(localStorage.getItem('authUser')) || '',
  selectedUserName: localStorage.getItem('selectedUserName') || '',
  permission: [],
  featureFlagsPermission: {}
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    onSelectedUser(state, action) {
      state.selectedUser = action.payload.selectedUser;
    },
    onSelectedUserName(state, action) {
      state.selectedUser = action.payload.selectedUserName;
    },
    onSetAuthUser(state, action) {
      state.authUser = action.payload.authUser;
    },
    onClearSelectedUser(state, action) {
      state.selectedUser = '';
    },
    onClearSelectedUserName(state, action) {
      state.selectedUserName = '';
    },
    onClearUserState(state, action) {
      state.selectedUser = '';
      state.authUser = '';
      state.selectedUserName = '';
    },
    getUserPermissionList(state, action) {
      state.permission = action.payload;
    },
    getFeatureFlagsPermissionList(state, action) {
      state.featureFlagsPermission = action.payload;
    }
  }
});

export default user.reducer;

export const {
  onSelectedUser,
  onClearSelectedUser,
  onSetAuthUser,
  onClearSelectedUserName,
  onSelectedUserName,
  onClearUserState,
  getUserPermissionList,
  getFeatureFlagsPermissionList
} = user.actions;
