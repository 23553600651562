import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown } from 'antd';
import { capitalize } from 'lodash';
import { onClearUserState } from 'store/reducers/user';
import { onUserLogout } from 'utils';
import { getUserByIdPayload } from 'utils/api.bodies';
import { getDecryptedToken, httpRequest } from 'utils/utility';

import { LOGIN, PROFILE_UPSERT, USER_ROLE } from 'shared/constants';
import { GET, GET_SELECT_DATA_URL } from 'shared/constants/apiUrl';
import { useI18n } from 'shared/hooks';
import { indexDB } from 'shared/utils';

const UserProfile = (props) => {
  const { authUser } = useSelector((state) => state.user);
  const authUserId = authUser?.uid;
  const authRole = authUser?.role;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imgUrl, setImgUrl] = useState('');

  const { getTValue, isOtherLangSelected } = useI18n();
  const upsertLang = getTValue('user-profile-menu')?.list;

  const companyUserToken = getDecryptedToken('company_token');
  const authUserToken = getDecryptedToken('token');

  const token = authRole === USER_ROLE.SUPER_ADMIN ? companyUserToken : authUserToken;

  useEffect(() => {
    if (token) {
      const getAuthUserImg = async (authUserId) => {
        const {
          response: { code, data },
          error
        } = await httpRequest(GET_SELECT_DATA_URL, getUserByIdPayload(authUserId));
        if (code === 200) {
          const authImg = data?.[0]?.pic;
          if (authImg) {
            setImgUrl(authImg);
          }
        }
      };
      getAuthUserImg(authUserId);
    }
  }, [token]);

  const USER_PROFILE_MENU = useMemo(() => {
    return [
      {
        icon: <UserOutlined />,
        label: upsertLang?.editProfile || 'Edit Profile',
        path: PROFILE_UPSERT
      },
      {
        icon: <LogoutOutlined />,
        label: upsertLang?.logout || 'Logout',
        path: '',
        danger: true
      }
    ].map((el, i) => ({ key: (i + 1).toString(), ...el }));
  }, [isOtherLangSelected]);

  const handleLogout = async () => {
    dispatch(onClearUserState());
    onUserLogout();
    indexDB.close();
    navigate(LOGIN);
    if (authRole === USER_ROLE.PACKEM_ADMIN) {
      // bcz to remove inject script for translation
      window.location.reload();
    }
  };

  const handleMenuClick = (menu) => {
    const currentObj = USER_PROFILE_MENU.find((el) => el.key === menu.key);
    currentObj.path ? navigate(currentObj.path) : handleLogout();
  };

  return (
    <span>
      <Dropdown
        menu={{
          items: USER_PROFILE_MENU,
          onClick: handleMenuClick
        }}
        placement="bottom"
        icon={<UserOutlined />}
        trigger={['click']}
      >
        <Button type="text">
          <Avatar
            size="small"
            style={{
              backgroundColor: '#f6ffed',
              color: '#389e0d',
              borderColor: '#b7eb8f',
              marginRight: 5
            }}
            src={imgUrl}
          >
            {!imgUrl && capitalize(authUser?.name?.[0])}
          </Avatar>
          {authUser?.name}
        </Button>
      </Dropdown>
    </span>
  );
};

export default UserProfile;
