// project import
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { FloatConfig } from 'FloatConfig';
import { App as AntdApp, ConfigProvider, Flex, Spin } from 'antd';
import { isEmpty } from 'lodash';
import AppRoutes from 'routes/AppRoutes';
import { getFeatureFlagsPermissionList, getUserPermissionList, onClearUserState } from 'store/reducers/user';
import { getDecryptedToken, onUserLogout } from 'utils';
import { getCompanyFeatureFlagPayload, getPermissionReadPayload } from 'utils/api.bodies';
import { httpRequest } from 'utils/utility';

import { GET_SELECT_DATA_URL, LANGUAGES, LOGIN, POST, USER_ROLE } from 'shared/constants';
import { useBaseState } from 'shared/hooks';
import 'shared/scss/global.scss';
import { openErrorMessage } from 'shared/utils';
import { Toast } from 'shared/utils';

import { ErrorBoundary } from 'components/common';

const englishLang = LANGUAGES['en-US'].toLowerCase();

const App = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { authUser, companyId } = useBaseState();
  const authRole = authUser?.role;
  const authLang = authUser?.lang;
  const isOtherAuthLang = authLang !== englishLang;

  const companyUserToken = getDecryptedToken('company_token');
  const companyUserExpireDate = companyUserToken?.exp * 1000;

  const authUserToken = getDecryptedToken('token');
  const authUserExpireDate = authUserToken?.exp * 1000;
  const { loading } = useSelector((state) => state.auth);
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const currentDate = Date.now();
  // const currentDate = companyUserExpireDate + 10; // for logout testing

  const isCompanyUserTokenExpired = companyUserExpireDate < currentDate;
  const isAuthUserTokenExpired = authUserExpireDate < currentDate;

  useEffect(() => {
    if (isCompanyUserTokenExpired || isAuthUserTokenExpired) {
      dispatch(onClearUserState());
      openErrorMessage({ message: `Token Expired!` });
      onUserLogout();
      navigate(`/${LOGIN}`);
    }
  }, [pathname, isCompanyUserTokenExpired, isAuthUserTokenExpired]);

  useEffect(() => {
    if (authRole) {
      const getPermissionList = async (role) => {
        setIsLoading(true);
        const {
          response: { code, data: authModules },
          error
        } = await httpRequest(GET_SELECT_DATA_URL, getPermissionReadPayload(role), POST, true);

        const isAdminUser = role === USER_ROLE.SUPER_ADMIN;
        if (!isAdminUser) {
          dispatch(getUserPermissionList(authModules || []));
          setIsLoading(false);
        }
        if (isAdminUser) {
          const {
            response: { code, data: allModules = [] },
            error
          } = await httpRequest(GET_SELECT_DATA_URL, getPermissionReadPayload(), POST, true);
          if (!isEmpty(allModules)) {
            let otherUsersModules = allModules.filter((el) => el.user_role !== USER_ROLE.SUPER_ADMIN).map((el) => el.module_name);
            const modules = authModules
              .map((el) => ({ ...el, isOnlyAssignToAdmin: !otherUsersModules.includes(el.module_name) }))
              .sort((a, b) => a.isOnlyAssignToAdmin - b.isOnlyAssignToAdmin);
            dispatch(getUserPermissionList(modules || []));
            setIsLoading(false);
          }
        }
      };
      getPermissionList(authRole);
    }
  }, [authRole]);

  useEffect(() => {
    if (companyId) {
      const getFeatureFlags = async () => {
        const {
          response: { code, data = [] },
          error
        } = await httpRequest(GET_SELECT_DATA_URL, JSON.stringify(getCompanyFeatureFlagPayload(companyId, true)));
        if (code === 200) {
          const result = data?.reduce((acc, currentItem) => {
            acc[currentItem?.name] = currentItem?.name;
            return acc;
          }, {});
          dispatch(getFeatureFlagsPermissionList(result || {}));
        }
      };
      getFeatureFlags();
    }
  }, [companyId]);

  if (loading) return <>Loading...</>;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#2EBB9F',
          colorBorder: '#E6EBF1',
          colorBorderSecondary: '#E6EBF1',
          colorBgLayout: '#FAFAFB',
          colorText: '#262626',
          fontFamily: '"Public Sans", sans-serif'
        },
        components: {
          Form: {
            itemMarginBottom: 16,
            verticalLabelPadding: 0
          }
        }
      }}
      componentSize="large"
    >
      <ErrorBoundary>
        <AntdApp
          notification={{
            placement: 'topRight'
          }}
        >
          {isLoading ? (
            <Flex justify="center" align="center" style={{ height: '100vh' }}>
              {/* <Spin indicator={<LoadingOutlined spin />} tip="Loading" size="large"> */}
              <Spin tip="Loading" size="large">
                <div
                  style={{
                    padding: 50
                  }}
                />
              </Spin>
            </Flex>
          ) : (
            <AppRoutes />
          )}
          <Toast />
          {/* {authUserToken && isOtherAuthLang && <FloatConfig />} */}
          {authUserToken && isOtherAuthLang && <div id="google_translate_element" />}
        </AntdApp>
      </ErrorBoundary>
    </ConfigProvider>
  );
};

export default App;
