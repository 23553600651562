import {
  CHART_CONFIG,
  CHART_INSIGHT,
  COMPANY,
  COMPANY_HIERARCHY,
  CUSTOM_CHARTS,
  DATASET_QUERY_MAP,
  DATASET_QUESTION,
  HOME,
  KPI_CONFIG_DATASET,
  KPI_CONFIG_DIMENSION,
  KPI_CONFIG_METRIC,
  KPI_CONFIG_TIMEFIELD,
  KPI_INSIGHT,
  PROFILE_UPSERT,
  ROLE_DASHBOARD_MAP,
  ROLE_PERMISSION,
  SALES_CONFIG,
  SCHEMA_MAP,
  SQL_FEEDBACK,
  TARGET_SALES,
  TEMPLATE,
  TENANT_SETTINGS,
  USER
} from './routePaths';

export const USER_ROLE = {
  SUPER_ADMIN: 'Admin',
  HELIOS_ADMIN: 'Helios-Admin_1.1.1',
  JMC_ADMIN: 'JMC-Admin_2.3.1',
  PACKEM_ADMIN: 'Packem-Admin_3.4.1',
  SELLVATION_ADMIN: 'Sellvation-Admin_4.5.1'
};

export const PERMISSION_TYPE = {
  LIST: 'List',
  CREATE: 'Create',
  UPDATE: 'Update',
  VIEW: 'View',
  DELETE: 'Delete'
};

const { SUPER_ADMIN, HELIOS_ADMIN, JMC_ADMIN, PACKEM_ADMIN, SELLVATION_ADMIN } = USER_ROLE;

const { LIST, CREATE, UPDATE, DELETE, VIEW } = PERMISSION_TYPE;

export const USER_PERMISSION = {
  [SUPER_ADMIN]: {
    [HOME]: [LIST],
    [CHART_CONFIG]: [LIST, CREATE, UPDATE, DELETE],
    [CHART_INSIGHT]: [LIST, CREATE, UPDATE, DELETE],
    [KPI_INSIGHT]: [LIST, CREATE, UPDATE, DELETE],
    [TARGET_SALES]: [LIST, CREATE, DELETE],
    [SALES_CONFIG]: [LIST, CREATE, UPDATE],
    [TENANT_SETTINGS]: [LIST, CREATE, UPDATE, DELETE],
    [CUSTOM_CHARTS]: [LIST, CREATE, UPDATE, DELETE],
    [SQL_FEEDBACK]: [LIST, UPDATE],
    [COMPANY]: [LIST, CREATE, UPDATE, DELETE],
    [COMPANY_HIERARCHY]: [LIST, CREATE, UPDATE, DELETE],
    [DATASET_QUERY_MAP]: [LIST, CREATE, UPDATE, DELETE],
    [ROLE_DASHBOARD_MAP]: [LIST, CREATE, UPDATE, DELETE],
    [SCHEMA_MAP]: [LIST, CREATE, UPDATE, DELETE],
    [USER]: [LIST, CREATE, UPDATE, DELETE, VIEW],
    [TEMPLATE]: [LIST, CREATE, UPDATE, DELETE, VIEW],
    [DATASET_QUESTION]: [LIST, CREATE, UPDATE, DELETE],
    [KPI_CONFIG_DATASET]: [LIST, CREATE, UPDATE, DELETE],
    [KPI_CONFIG_DIMENSION]: [LIST, CREATE, UPDATE, DELETE],
    [KPI_CONFIG_METRIC]: [LIST, CREATE, UPDATE, DELETE],
    [KPI_CONFIG_TIMEFIELD]: [LIST, CREATE, UPDATE, DELETE],
    [ROLE_PERMISSION]: [LIST],
    [PROFILE_UPSERT]: [LIST] //not upsert case
  },
  [HELIOS_ADMIN]: {
    [HOME]: [LIST],
    [CHART_CONFIG]: [LIST, CREATE, UPDATE, DELETE],
    [CHART_INSIGHT]: [LIST, CREATE, UPDATE, DELETE],
    [KPI_INSIGHT]: [LIST, CREATE, UPDATE, DELETE],
    [TARGET_SALES]: [LIST, CREATE, DELETE],
    [SALES_CONFIG]: [LIST, CREATE, UPDATE],
    [TENANT_SETTINGS]: [LIST, CREATE, UPDATE, DELETE],
    [CUSTOM_CHARTS]: [LIST, CREATE, UPDATE, DELETE],
    [PROFILE_UPSERT]: [LIST] //not upsert case
  },
  [JMC_ADMIN]: {
    [HOME]: [LIST],
    [CHART_CONFIG]: [LIST, CREATE, UPDATE, DELETE],
    [CHART_INSIGHT]: [LIST, CREATE, UPDATE, DELETE],
    [KPI_INSIGHT]: [LIST, CREATE, UPDATE, DELETE],
    [TARGET_SALES]: [LIST, CREATE, DELETE],
    [SALES_CONFIG]: [LIST, CREATE, UPDATE],
    [TENANT_SETTINGS]: [LIST, CREATE, UPDATE, DELETE],
    [CUSTOM_CHARTS]: [LIST, CREATE, UPDATE, DELETE],
    [PROFILE_UPSERT]: [LIST] //not upsert case
  },
  [PACKEM_ADMIN]: {
    [HOME]: [LIST],
    [CHART_CONFIG]: [LIST, CREATE, UPDATE, DELETE],
    [CHART_INSIGHT]: [LIST, CREATE, UPDATE, DELETE],
    [KPI_INSIGHT]: [LIST, CREATE, UPDATE, DELETE],
    [TARGET_SALES]: [LIST, CREATE, DELETE],
    [SALES_CONFIG]: [LIST, CREATE, UPDATE],
    [TENANT_SETTINGS]: [LIST, CREATE, UPDATE, DELETE],
    [CUSTOM_CHARTS]: [LIST, CREATE, UPDATE, DELETE],
    [PROFILE_UPSERT]: [LIST] //not upsert case
  },
  [SELLVATION_ADMIN]: {
    [HOME]: [LIST],
    [CHART_CONFIG]: [LIST, CREATE, UPDATE, DELETE],
    [CHART_INSIGHT]: [LIST, CREATE, UPDATE, DELETE],
    [KPI_INSIGHT]: [LIST, CREATE, UPDATE, DELETE],
    [TARGET_SALES]: [LIST, CREATE, DELETE],
    [SALES_CONFIG]: [LIST, CREATE, UPDATE],
    [TENANT_SETTINGS]: [LIST, CREATE, UPDATE, DELETE],
    [CUSTOM_CHARTS]: [LIST, CREATE, UPDATE, DELETE],
    [PROFILE_UPSERT]: [LIST] //not upsert case
  }
};
