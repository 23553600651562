export const BASE_URL = process.env.REACT_APP_API_URL;

// Methods
export const POST = 'POST';
export const GET = 'GET';

export const GATEWAY_ENDPOINT_PREFIX = '/srvc';

// AUTH API url
export const LOGIN_URL = `${BASE_URL}/login`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/forgot-password`;
export const RESET_PASSWORD_URL = `${BASE_URL}/reset-password`;
export const GET_WEIGHTED_AVG_URL = `${BASE_URL}/weighted-avg`;
export const TOKEN_URL = `${BASE_URL}/token`;
export const UPLOAD_IMAGE = `${BASE_URL}/uploads`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}/change-password`;

// Component API URL
export const GET_SELECT_DATA_URL = '/api/select';
export const GET_RESTBI_SELECT_DATA_URL = '/restbi/api/select';
export const ADD_DATA_URL = `/api/add`;
export const EDIT_DATA_URL = `/api/edit`;
export const DELETE_DATA_URL = `/api/delete`;
export const USER_TOKEN_URL = `/user-token`;
export const GET_TRANSLATE = `/api/translate/text`;
export const NL_2_SQL = `/ml-service/rag`;
export const TABLES_SELECT = `${NL_2_SQL}/datasets`;
export const ASK_DB_SELECT = `${NL_2_SQL}/ask-db`;
export const GENERATE_ANS = `${NL_2_SQL}/generate-answer`;
export const VISUAL_SELECT = `${NL_2_SQL}/get-chart`;
export const VISUAL_PLAYGROUND_SELECT = `${NL_2_SQL}/generate-viz`;
export const GET_LLM_MODELS = `${NL_2_SQL}/get-llm-model`;
export const USER_ADD = `/user/add`;
export const USER_EDIT = `/user/edit`;
