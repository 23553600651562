// apiUtils.js

export const decodeToken = (token) => {
  try {
    const tokenPayload = token?.split('.')[1];
    const decodedPayload = atob(tokenPayload);
    return JSON.parse(decodedPayload);
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};
