import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Image, Upload, message } from 'antd';
import { getValidData } from 'utils/utility';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const ImgUpload = (props) => {
  const { name, value, onChange, fileList, setFileList, ...rest } = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const customRequest = ({ file, onSuccess, onError }) => {
    const newFileList = [
      {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url: URL.createObjectURL(file)
      }
    ];
    setFileList(newFileList);
    onSuccess('ok');
    onChange && onChange(file);
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('You can only upload image files!');
      return false;
    }

    const isLt5MB = file.size / 1024 / 1024 < 5;
    if (!isLt5MB) {
      message.error('Image must smaller than 5MB!');
      return false;
    }

    return true;
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none'
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <>
      <Upload
        listType="picture-circle"
        fileList={fileList}
        action={false}
        onPreview={handlePreview}
        maxCount={1}
        name={name}
        accept="image/png, image/jpeg"
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        onRemove={(file) => {
          console.log('file', file);
          const newFileList = fileList.filter((f) => f.uid !== file.uid);
          setFileList(newFileList);
          onChange && onChange('');
        }}
        {...rest}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: 'none'
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage('')
          }}
          src={previewImage}
        />
      )}
    </>
  );
};
