import React, { useEffect, useState } from 'react';
import { lazy } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import {
  CHART_CONFIG,
  CHART_CONFIG_UPSERT,
  CHART_DATASET_MAP,
  CHART_DATASET_MAP_UPSERT,
  CHART_INSIGHT,
  CHART_INSIGHT_UPSERT,
  COMPANY,
  COMPANY_HIERARCHY,
  COMPANY_HIERARCHY_UPSERT,
  COMPANY_UPSERT,
  CUSTOM_CHARTS,
  CUSTOM_CHARTS_UPSERT,
  DATASET_QUERY_MAP,
  DATASET_QUERY_MAP_UPSERT,
  DATASET_QUESTION,
  DATASET_QUESTION_UPSERT,
  EVENTS,
  EVENTS_UPSERT,
  FEATURE_FLAG,
  FEATURE_FLAG_UPSERT,
  FORGOT_PASSWORD,
  HOME,
  KPI_CONFIG_DATASET,
  KPI_CONFIG_DATASET_UPSERT,
  KPI_CONFIG_DIMENSION,
  KPI_CONFIG_DIMENSION_UPSERT,
  KPI_CONFIG_METRIC,
  KPI_CONFIG_METRIC_UPSERT,
  KPI_CONFIG_TIMEFIELD,
  KPI_CONFIG_TIMEFIELD_UPSERT,
  KPI_INSIGHT,
  KPI_INSIGHT_UPSERT,
  LOGIN,
  PROFILE_UPSERT,
  RAG_SAMPLE,
  RAG_SAMPLE_UPSERT,
  RESET_PASSWORD,
  ROLE_DASHBOARD_MAP,
  ROLE_DASHBOARD_MAP_UPSERT,
  ROLE_PERMISSION,
  ROLE_PERMISSION_UPSERT,
  SALES_CONFIG,
  SALES_CONFIG_UPSERT,
  SCHEMA_MAP,
  SCHEMA_MAP_UPSERT,
  SQL_FEEDBACK,
  SQL_FEEDBACK_UPSERT,
  SUGGESTIVE_INSIGHTS,
  SUGGESTIVE_INSIGHTS_UPSERT,
  TARGET_SALES,
  TARGET_SALES_UPSERT,
  TEMPLATE,
  TEMPLATE_UPSERT,
  TENANT_SETTINGS,
  TENANT_SETTINGS_UPSERT,
  USER,
  USER_UPSERT
} from 'shared/constants';
import { useBaseState } from 'shared/hooks/useBaseState';

import { Loadable, PageNotFound, Unauthorize } from 'components/common';
import MainLayout from 'components/layout/index';

const hasUserPermission = (authPermission, route) => {
  const { routePath, permissionType, component: Component } = route;
  const isSalesConfigPage = routePath.includes(SALES_CONFIG);
  const nakedPath = isSalesConfigPage ? routePath.split('/')[2] : routePath.split('/')[0];
  const pagePermission = authPermission.find((el) => el.module_name === nakedPath);
  // console.log('pagePermission', routePath, '===>>>', pagePermission);
  if (!pagePermission) return <Unauthorize />;
  const isList = pagePermission?.can_list === permissionType?.canList;
  const isCreate = pagePermission?.can_create === permissionType?.canCreate;
  const isUpdate = pagePermission?.can_update === permissionType?.canUpdate;
  const hasPermission = isList || isCreate || isUpdate;
  // console.log('hasPermission>>>>>>>>', hasPermission, isList, isCreate, isUpdate);
  return hasPermission ? <Component /> : <Unauthorize />;
};

const checkPrivateRoutes = (isLoggedIn, authPermission, route, ...params) =>
  isLoggedIn ? hasUserPermission(authPermission, route) : <Navigate to={`/${LOGIN}`} />;

const checkAuthRoutes = (isLoggedIn, authPermission, { component: Component }, ...params) =>
  isLoggedIn ? <Navigate to={`/${HOME}`} /> : <Component />;

const getPaths = (path, str) => (path.includes(`${str}?`) ? [path.split(`/:${str}`)[0], path.split(`/:${str}`)[0] + `/:${str}`] : [path]);

const mapRoutes = (routes, callback, isLoggedIn, authPermission = [], ...rest) => {
  return routes.flatMap((route) => {
    // Create new routes based on condition
    const path = route.routePath;
    const isSalesConfigPage = path.includes(SALES_CONFIG);
    const [createPath, updatePath] = isSalesConfigPage ? getPaths(path, 'configId') : getPaths(path, 'id');
    const newRoutes =
      createPath && updatePath
        ? [
            { ...route, routePath: createPath, permissionType: { canCreate: true } },
            { ...route, routePath: updatePath, permissionType: { canUpdate: true } }
          ]
        : [{ ...route, permissionType: { canList: true } }];

    return newRoutes.map((newRoute) => {
      const { routePath, children } = newRoute;

      return {
        path: routePath,
        element: callback ? callback(isLoggedIn, authPermission, newRoute, ...rest) : hasUserPermission(authPermission, newRoute),
        ...(children && children.length > 0 && { children: mapRoutes(children, callback, isLoggedIn, authPermission, ...rest) })
      };
    });
  });
};

const authRoutes = [
  { routePath: '', component: Loadable(lazy(() => import('components/auth/Login'))) },
  { routePath: LOGIN, component: Loadable(lazy(() => import('components/auth/Login'))) },
  { routePath: FORGOT_PASSWORD, component: Loadable(lazy(() => import('components/auth/ForgotPassword'))) },
  { routePath: RESET_PASSWORD, component: Loadable(lazy(() => import('components/auth/ResetPassword'))) }
];

const publicRoutes = [
  { path: 'about', element: <div>About Page</div> },
  { path: '*', element: <PageNotFound /> }
];

const privateRoutes = [
  { routePath: HOME, component: Loadable(lazy(() => import('components/dashboard'))) },

  { routePath: PROFILE_UPSERT, component: Loadable(lazy(() => import('components/auth/EditProfile'))) },

  { routePath: TENANT_SETTINGS, component: Loadable(lazy(() => import('components/tenant-settings'))) },
  {
    routePath: TENANT_SETTINGS_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/tenant-settings/Upsert')))
  },

  { routePath: CHART_CONFIG, component: Loadable(lazy(() => import('components/chart-config'))) },
  {
    routePath: CHART_CONFIG_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/chart-config/Upsert')))
  },

  { routePath: CHART_INSIGHT, component: Loadable(lazy(() => import('components/chart-insight'))) },
  {
    routePath: CHART_INSIGHT_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/chart-insight/Upsert')))
  },

  { routePath: KPI_INSIGHT, component: Loadable(lazy(() => import('components/kpi-insight'))) },
  {
    routePath: KPI_INSIGHT_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/kpi-insight/Upsert')))
  },

  {
    routePath: TARGET_SALES,
    component: Loadable(lazy(() => import('components/target-sales')))
  },
  {
    routePath: TARGET_SALES_UPSERT,
    component: Loadable(lazy(() => import('components/target-sales/Upsert')))
  },
  {
    routePath: TARGET_SALES + `/:id/${SALES_CONFIG}`,
    component: Loadable(lazy(() => import('components/target-sales/SalesConfigList')))
  },
  {
    routePath: TARGET_SALES + `/:id/${SALES_CONFIG_UPSERT}` + '/:configId?',
    component: Loadable(lazy(() => import('components/target-sales/Upsert')))
  },
  { routePath: CUSTOM_CHARTS, component: Loadable(lazy(() => import('components/custom-charts'))) },
  {
    routePath: CUSTOM_CHARTS_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/custom-charts/Upsert')))
  },

  { routePath: SQL_FEEDBACK, component: Loadable(lazy(() => import('components/sql-feedback'))) },
  {
    routePath: SQL_FEEDBACK_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/sql-feedback/Upsert')))
  },

  { routePath: COMPANY, component: Loadable(lazy(() => import('components/company'))) },
  {
    routePath: COMPANY_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/company/Upsert')))
  },

  { routePath: COMPANY_HIERARCHY, component: Loadable(lazy(() => import('components/company-hierarchy'))) },
  {
    routePath: COMPANY_HIERARCHY_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/company-hierarchy/Upsert')))
  },

  { routePath: DATASET_QUERY_MAP, component: Loadable(lazy(() => import('components/dataset-query-map'))) },
  {
    routePath: DATASET_QUERY_MAP_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/dataset-query-map/Upsert')))
  },

  { routePath: ROLE_DASHBOARD_MAP, component: Loadable(lazy(() => import('components/role-dashboard-map'))) },
  {
    routePath: ROLE_DASHBOARD_MAP_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/role-dashboard-map/Upsert')))
  },

  { routePath: SCHEMA_MAP, component: Loadable(lazy(() => import('components/schema-map'))) },
  {
    routePath: SCHEMA_MAP_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/schema-map/Upsert')))
  },

  { routePath: USER, component: Loadable(lazy(() => import('components/user'))) },
  { routePath: USER_UPSERT + '/:id?', component: Loadable(lazy(() => import('components/user/Upsert'))) },

  { routePath: TEMPLATE, component: Loadable(lazy(() => import('components/template'))) },
  {
    routePath: TEMPLATE_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/template/Upsert')))
  },

  { routePath: DATASET_QUESTION, component: Loadable(lazy(() => import('components/dataset-question'))) },
  {
    routePath: DATASET_QUESTION_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/dataset-question/Upsert')))
  },

  { routePath: KPI_CONFIG_DATASET, component: Loadable(lazy(() => import('components/kpi-config-dataset'))) },
  {
    routePath: KPI_CONFIG_DATASET_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/kpi-config-dataset/Upsert')))
  },

  { routePath: KPI_CONFIG_DIMENSION, component: Loadable(lazy(() => import('components/kpi-config-dimension'))) },
  {
    routePath: KPI_CONFIG_DIMENSION_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/kpi-config-dimension/Upsert')))
  },

  { routePath: KPI_CONFIG_METRIC, component: Loadable(lazy(() => import('components/kpi-config-metric'))) },
  {
    routePath: KPI_CONFIG_METRIC_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/kpi-config-metric/Upsert')))
  },

  { routePath: KPI_CONFIG_TIMEFIELD, component: Loadable(lazy(() => import('components/kpi-config-timefield'))) },
  {
    routePath: KPI_CONFIG_TIMEFIELD_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/kpi-config-timefield/Upsert')))
  },

  { routePath: ROLE_PERMISSION, component: Loadable(lazy(() => import('components/role-permission'))) },
  {
    routePath: ROLE_PERMISSION_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/role-permission/Upsert')))
  },
  { routePath: RAG_SAMPLE, component: Loadable(lazy(() => import('components/rag-sample'))) },
  {
    routePath: RAG_SAMPLE_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/rag-sample/Upsert')))
  },
  { routePath: FEATURE_FLAG, component: Loadable(lazy(() => import('components/feature-flag'))) },
  {
    routePath: FEATURE_FLAG_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/feature-flag/Upsert')))
  },
  { routePath: SUGGESTIVE_INSIGHTS, component: Loadable(lazy(() => import('components/suggestive-insights'))) },
  {
    routePath: SUGGESTIVE_INSIGHTS_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/suggestive-insights/Upsert')))
  },
  { routePath: CHART_DATASET_MAP, component: Loadable(lazy(() => import('components/chart-dataset-map'))) },
  {
    routePath: CHART_DATASET_MAP_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/chart-dataset-map/Upsert')))
  },
  { routePath: EVENTS, component: Loadable(lazy(() => import('components/events'))) },
  {
    routePath: EVENTS_UPSERT + '/:id?',
    component: Loadable(lazy(() => import('components/events/Upsert')))
  }
];

export default function AppRoutes() {
  const { authRole, authPermission } = useBaseState();
  const [routes, setRoutes] = useState([]);
  // console.log('authPermission???????????????????', authPermission);
  useEffect(() => {
    const isLoggedIn = !!authRole;
    // const userAccessRoutes = getUserAccessRoutes(privateRoutes, authPermission);
    const routePaths = [
      {
        path: '/',
        element: isLoggedIn ? <MainLayout /> : <Outlet />,
        children: [
          ...mapRoutes(privateRoutes, checkPrivateRoutes, isLoggedIn, authPermission),
          ...mapRoutes(authRoutes, checkAuthRoutes, isLoggedIn)
        ]
      },
      ...publicRoutes
    ];
    setRoutes(routePaths);
  }, [authRole, authPermission?.length]);

  // console.log('routes', routes);

  return useRoutes(routes);
}
