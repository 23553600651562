import React from 'react';

import { DeleteOutlined, EyeOutlined, FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space } from 'antd';
import PropTypes from 'prop-types';

export const ActionBtn = (props) => {
  const { onView, onEdit, onDelete } = props;
  return (
    <Space>
      {onView && (
        <Button style={{ border: 0, padding: 7, backgroundColor: 'transparent' }} onClick={onView}>
          <EyeOutlined />
        </Button>
      )}
      {onEdit && (
        <Button style={{ border: 0, padding: 7, backgroundColor: 'transparent' }} onClick={onEdit}>
          <FormOutlined />
        </Button>
      )}
      {onDelete && (
        <Popconfirm
          title="Delete the item"
          description="Are you sure to delete this item?"
          onConfirm={onDelete}
          icon={
            <QuestionCircleOutlined
              style={{
                color: 'red'
              }}
            />
          }
        >
          <Button style={{ border: 0, padding: 7, backgroundColor: 'transparent' }} danger>
            <DeleteOutlined style={{ color: '#ff4d4f' }} />
          </Button>
        </Popconfirm>
      )}
    </Space>
  );
};
