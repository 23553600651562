import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Select, Switch } from 'antd';
import { lowerCase } from 'lodash';
import { getPascalCaseText } from 'utils/utility';

import { DEFAULT_DATE_FORMATE } from 'shared/constants';

const { Option } = Select;

let Obj = {};

export const FormItem = (props) => {
  const {
    type = 'text',
    disabled = false,
    label,
    name,
    rules,
    children,
    placeholder,
    fieldNames,
    dependencies = [],
    picker = 'date',
    datePickerStyle = {},
    isLabelVisible = true,
    formItemStyle = undefined,
    hasFeedback = true,
    textAreaRows = 10,
    date = {
      format: DEFAULT_DATE_FORMATE,
      showTime: false
    }
  } = props;
  //   name field should name as db name

  // Obj = { ...Obj, [name]: label };
  // console.log('Obj', Obj);

  return (
    <Form.Item
      label={isLabelVisible ? label || getPascalCaseText(name) : undefined}
      name={name}
      rules={rules}
      validateTrigger="onBlur"
      dependencies={dependencies}
      style={formItemStyle}
      hasFeedback={hasFeedback}
    >
      {fieldNames ? (
        <Select mode={fieldNames?.mode} placeholder={placeholder || label} disabled={disabled} maxCount={fieldNames?.maxCount}>
          {fieldNames.options.map((el, i) => (
            <Option value={el[fieldNames.value]} key={`${name}-${i}`}>
              {el[fieldNames.label]}
            </Option>
          ))}
        </Select>
      ) : children ? (
        children
      ) : type === 'password' ? (
        <Input.Password placeholder={placeholder || label} disabled={disabled} />
      ) : type === 'date' ? (
        <DatePicker picker={lowerCase(picker)} style={datePickerStyle} disabled={disabled} format={date.format} showTime={date.showTime} />
      ) : type === 'textarea' ? (
        <Input.TextArea placeholder={placeholder || label} disabled={disabled} rows={textAreaRows} />
      ) : type === 'switch' ? (
        <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
      ) : (
        <Input placeholder={placeholder || label} disabled={disabled} />
      )}
    </Form.Item>
  );
};
