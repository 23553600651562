import { useCallback, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';

import { SearchOutlined } from '@ant-design/icons';
import { Input, Space, Table } from 'antd';
import { camelCase, debounce, isArray, startCase } from 'lodash';
import PropTypes from 'prop-types';
import { getEnglishTextPayload } from 'utils/api.bodies';
import { getGoogleTranslatorData, httpRequest } from 'utils/utility';

import { GET_TRANSLATE } from 'shared/constants';
import { useBaseState, useI18n } from 'shared/hooks';

export const AntdTable = (props) => {
  const {
    rows = [],
    columns = [],
    onScroll = () => {},
    loading = false,
    pagination = false,
    rowSelection = undefined,
    tableRowKey = 'id',
    expandable = undefined,
    onRow = () => {},
    pageUrl = '',
    bordered = true
  } = props;

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const authUserSelectedLang = localStorage.getItem('glang');
  // console.log('authUserSelectedLang', authUserSelectedLang);
  const searchInput = useRef(null);

  const { getTValue, isOtherLangSelected } = useI18n();

  const tableCols = getTValue(`${pageUrl}.index.table`);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm({
      closeDropdown: false
    });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getEnglishText = async (searchText, sourceLang) => {
    const data = await getGoogleTranslatorData(searchText, sourceLang);
    return data;
  };

  const getDebounceData = useCallback(
    debounce(async (searchQuery, confirm, dataIndex, sl) => {
      console.log('sldddddddd', sl);
      const searchText = await getEnglishText(searchQuery, sl);
      console.log('searchQuery>>>>>>', searchQuery);
      console.log('searchText>>>>>>>', searchText);
      handleSearch(searchText ? [searchText] : [], confirm, dataIndex);
    }, 500),
    []
  );

  const getColumnSearchProps = (dataIndex, columnTitle, searchText) =>
    dataIndex === 'action'
      ? {}
      : {
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
              role="presentation"
              style={{
                padding: 8
              }}
              onKeyDown={(e) => e.stopPropagation()}
            >
              <Input
                ref={searchInput}
                placeholder={`Search ${columnTitle}`}
                value={selectedKeys[0]}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                  !authUserSelectedLang
                    ? handleSearch(e.target.value ? [e.target.value] : [], confirm, dataIndex)
                    : getDebounceData(e.target.value, confirm, dataIndex, authUserSelectedLang);
                }}
                style={{
                  marginBottom: 8
                  // display: 'block'
                }}
              />
            </div>
          ),
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{
                color: filtered ? '#1677ff' : undefined
              }}
            />
          ),
          // onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
          onFilter: (value, record) => {
            // console.log('search?????????????', searchText);
            return !authUserSelectedLang
              ? record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase())
              : record[dataIndex]?.toString().toLowerCase().includes(searchText?.toLowerCase());
          },
          onFilterDropdownOpenChange: (visible) => {
            if (visible) {
              setTimeout(() => searchInput.current?.select(), 100);
            }
          },
          render: (text) =>
            searchedColumn === dataIndex ? (
              <Highlighter
                highlightStyle={{
                  backgroundColor: '#ffc069',
                  padding: 0
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
              />
            ) : (
              text
            )
        };

  const COLUMNS = useMemo(() => {
    const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);
    const getColumnTitle = (name) => startCase(camelCase(name?.replace(/_/g, ' ')));

    const cols = columns.map((el) => {
      const dataIndex = el.dataIndex;
      return {
        title: getColumnTitle(dataIndex),
        sorter: dataIndex === 'action' ? false : (a, b) => sorter(a[dataIndex], b[dataIndex]),
        ...getColumnSearchProps(dataIndex, getColumnTitle(dataIndex), searchText),
        ...el
      };
    });

    const newColumns = isOtherLangSelected ? cols.map((el) => ({ ...el, title: tableCols?.[el.dataIndex] || el.title })) : cols;

    return newColumns;
  }, [columns.length, searchText, searchInput, searchedColumn, isOtherLangSelected, authUserSelectedLang]);

  // console.log(
  //   'COLUMNS',
  //   COLUMNS.reduce((acc, { title, dataIndex }) => {
  //     acc[dataIndex] = title;
  //     return acc;
  //   }, {})
  // );

  const onSelect = (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  };

  return (
    <>
      <Table
        dataSource={rows}
        columns={COLUMNS}
        onScroll={onScroll}
        scroll={{ y: 'calc(100vh - 280px)' }}
        pagination={pagination}
        loading={loading}
        rowKey={(record) => record[tableRowKey]}
        rowSelection={rowSelection}
        onSelect={onSelect}
        expandable={expandable}
        onRow={onRow}
        bordered={bordered}
      />
    </>
  );
};

AntdTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  onScroll: PropTypes.func,
  loading: PropTypes.bool,
  pagination: PropTypes.bool,
  rowSelection: PropTypes.object,
  key: PropTypes.string
};
