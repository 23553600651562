import { JsonEditor as Editor } from 'json-edit-react';

export const JsonEditor = (props) => {
  const { value = {}, onChange = () => {}, ...rest } = props;

  return (
    <Editor
      onUpdate={({ newData }) => onChange(newData)}
      data={value}
      restrictEdit={true}
      restrictDelete={true}
      restrictAdd={true}
      showCollectionCount={false}
      showArrayIndices={false}
      maxWidth="100%"
      {...rest}
    />
  );
};
