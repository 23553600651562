import { Button } from 'antd';
import logo from 'assets/logo.json';

const solbiLogo = `data:image/png;base64,${logo.solbiBase64Png}`;

export const SolBiLogo = (props) => {
  const { onClick = () => {}, size = 'small' } = props;
  return (
    <Button size={size} onClick={onClick} type="text" style={{ backgroundColor: 'white', height: '100%' }}>
      <img style={{ width: 100 }} src={solbiLogo} alt="logo" />
    </Button>
  );
};
