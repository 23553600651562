import { useNavigate } from 'react-router-dom';

import { Button, Col, Row, Space, Typography } from 'antd';
import { getPascalCaseText } from 'utils/utility';

import { useI18n } from 'shared/hooks';

const { Title } = Typography;

export const RouteHeader = (props) => {
  const {
    pageAddURL,
    pageBackURL,
    pageTitle,
    addTitle = 'Add',
    backTitle = 'Back',
    pageUrl = '',
    upsertId = '',
    isIndexPage = true
  } = props;

  const { getTValue } = useI18n();

  const pageHeader = getTValue(`${pageUrl}.${isIndexPage ? 'index' : 'upsert'}.routeHeader`);

  const navigate = useNavigate();

  const newPageTitle = pageTitle || pageHeader?.title || getPascalCaseText(pageUrl);

  const title = isIndexPage ? newPageTitle : `${upsertId ? pageHeader?.update || 'Update' : pageHeader.add || 'Add'} ${newPageTitle}`;

  return (
    <Row gutter={[0, 16]}>
      {/* <Col span={24}>
        <Breadcrumb items={BREADCRUMB} />
      </Col> */}

      <Col span={24}>
        <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
          <Title level={4} style={{ margin: 0 }}>
            {title}
          </Title>

          <Space>
            {pageBackURL && (
              <Button size="large" onClick={() => navigate(`/${pageBackURL}`)}>
                {pageHeader.back || backTitle}
              </Button>
            )}

            {pageAddURL && (
              <Button type="primary" size="large" onClick={() => navigate(`/${pageAddURL}`)}>
                {pageHeader.add || addTitle}
              </Button>
            )}
          </Space>
        </Row>
      </Col>
    </Row>
  );
};
