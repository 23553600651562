import { Suspense } from 'react';
import { Flex } from 'antd';
import { Loader } from './Loader';

export const Loadable = (Component) => (props) => {
  return (
    <Suspense
      fallback={
        <Flex justify="center" align="center" style={{ height: '100%' }}>
          <Loader />
        </Flex>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};
