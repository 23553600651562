import Dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';

import { DEFAULT_DATE_FORMATE } from 'shared/constants';

Dayjs.extend(quarterOfYear);
Dayjs.extend(utc);

export const getFormattedDate = (date, format = DEFAULT_DATE_FORMATE) => Dayjs(date).format(format);

export const getFormattedDateInDayjsObj = (date) => Dayjs(date);
