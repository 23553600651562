export const CHART_LIBRARY = {
  CHARTJS: 'Chart Js',
  ECHARTS: 'Echarts',
  PLOTLY: 'Plotly'
};

const { CHARTJS, ECHARTS, PLOTLY } = CHART_LIBRARY;

export const CHART_TYPE = {
  LINE: 'Line',
  BAR: 'Bar',
  LINEBAR: 'Line + Bar',
  PIE: 'Pie',
  SUNBURST: 'Sunburst'
};

const { LINE, BAR, LINEBAR, PIE, SUNBURST } = CHART_TYPE;

export const CHART_BAR_TYPE = {
  VERTICAL: 'Vertical',
  HORIZONTAL: 'Horizontal'
};

export const PICKER_TYPE = {
  MONTH: 'Month',
  QUARTER: 'Quarter',
  YEAR: 'Year'
};

export const GRAPH_COLORS = {
  0: '#FF6384', //red
  1: '#FF9F40', //orange
  2: '#FFCD56', //yellow
  3: '#4BC0C0', //green
  4: '#36A2EB', //blue
  5: '#9966FF' //purple
  // 6: '#C9CBCF' //grey
};

// const GRPAH_KEYS = {
//   xAxis: 'franchise_name',
//   yAxis: 'asum',
//   datasetLabel: 'Dataset 1'
// };

const GRPAH_KEYS = {
  xAxis: '',
  yAxis: '',
  datasetLabel: '',
  type: ''
};

export const GRAPH_MAP_KEYS = {
  [LINE]: [{ ...GRPAH_KEYS, type: LINE.toLowerCase() }],
  [BAR]: [{ ...GRPAH_KEYS, type: BAR.toLowerCase() }],
  [LINEBAR]: [
    { ...GRPAH_KEYS, type: LINE.toLowerCase() },
    { ...GRPAH_KEYS, type: BAR.toLowerCase() }
  ],
  [PIE]: [{ ...GRPAH_KEYS, type: PIE.toLowerCase() }],
  [SUNBURST]: [
    {
      xAxis: '',
      // xAxis: 'product_category_name',
      children: [{ xAxis: '', children: [{ xAxis: '', yAxis: '' }] }],
      // children: [{ xAxis: 'product_name', children: [{ xAxis: 'item_name', yAxis: 'count' }] }],
      type: SUNBURST.toLowerCase()
    }
  ]
};

export const CHART_TEMPLATE = {
  [CHARTJS]: {
    [LINE]: {
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: ''
          }
        }
      },
      data: {
        labels: [],
        datasets: [
          {
            type: '',
            label: '',
            data: [],
            borderColor: '',
            backgroundColor: ''
          }
        ]
      }
    },
    [BAR]: {
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: ''
          }
        }
      },
      data: {
        labels: [],
        datasets: [
          {
            type: '',
            label: '',
            data: [],
            borderColor: '',
            backgroundColor: ''
          }
        ]
      }
    },
    [PIE]: {
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: ''
          }
        }
      },
      data: {
        labels: [],
        datasets: [
          {
            type: '',
            label: '',
            data: [],
            backgroundColor: []
          }
        ]
      }
    }
  },
  [ECHARTS]: {
    [LINE]: {
      option: {
        xAxis: {
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '',
            data: [],
            smooth: true,
            type: ''
          }
        ]
      }
    },
    [BAR]: {
      option: {
        xAxis: {
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '',
            data: [],
            smooth: true,
            type: ''
          }
        ]
      }
    },
    [PIE]: {
      option: {
        title: {
          text: '',
          subtext: '',
          left: ''
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '',
            data: [{ value: '', name: '' }],
            smooth: true,
            type: '',
            radius: '50%',
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    },
    [SUNBURST]: {
      option: {
        tooltip: {
          trigger: 'item'
        },
        series: {
          type: 'sunburst',
          data: [{ name: '', children: [] }],
          radius: [0, '90%'],
          label: {
            rotate: 'radial'
          }
        }
      }
    }
  },
  [PLOTLY]: {
    [LINE]: {
      data: [
        {
          marker: { color: '' }
        },
        { type: '', x: [], y: [] } // x is labels and y is data
      ],
      layout: { title: '' }
    },
    [BAR]: {
      data: [
        {
          marker: { color: '' }
        },
        { type: '', x: [], y: [] } // x is labels and y is data
      ],
      layout: { title: '' }
    },
    [PIE]: {
      data: [
        {
          marker: { color: '' }
        },
        { type: '', labels: [], values: [] } // x is labels and y is data
      ],
      layout: { title: '' }
    },
    [SUNBURST]: {
      data: [{ type: '', labels: [], parents: [], values: [] }],
      layout: {
        title: 'Sunburst Chart',
        margin: { l: 0, r: 0, b: 0, t: 0 }
      }
    }
  }
};
