export const TOAST_TYPE = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

export const DEFAULT_DATE_FORMATE = 'YYYY-MM-DD';

export const IS_TRANSLATE_FROM_GOOGLE = true;

export const LANGUAGES = {
  'en-US': 'English',
  'pt-BR': 'Portuguese',
  'nl-NL': 'Dutch'
};

export const TIME_GRAIN = [
  { label: 'Daily', value: 'day' },
  { label: 'Weekly', value: 'week' },
  { label: 'Monthly', value: 'month' },
  { label: 'Quarterly', value: 'quarter' },
  { label: 'Yearly', value: 'year' }
];

export const FEATURE_FLAGS = {
  DISPLAY_VISUAL_PLAYGROUND: 'DISPLAY_VISUAL_PLAYGROUND',
  DISPLAY_LLM_SWITCH: 'DISPLAY_LLM_SWITCH'
};
