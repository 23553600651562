import { camelCase, isEmpty, isNil, startCase } from 'lodash';

import {
  ADD_DATA_URL,
  BASE_URL,
  DELETE_DATA_URL,
  EDIT_DATA_URL,
  GATEWAY_ENDPOINT_PREFIX,
  GET_RESTBI_SELECT_DATA_URL,
  GET_SELECT_DATA_URL,
  POST
} from 'shared/constants';
import { openErrorMessage } from 'shared/utils';

export function getCompanyId() {
  const token = localStorage.getItem('token');
  var base64Url = token?.split('.')[1];
  var base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  if (base64) {
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload).comp_id;
  }
  return 0;
}

const handleClearSelectedUserData = () => {
  localStorage.removeItem(`company_token`);
  localStorage.removeItem(`get_comp_role`);
  localStorage.removeItem(`get_comp_id`);
  localStorage.removeItem(`selectedUser`);
  localStorage.removeItem(`authUser`);
  localStorage.removeItem(`selectedUserName`);
  localStorage.removeItem(`glang`);
  localStorage.removeItem('gtck');
};

const handleClearLoggedInUserData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('company_id');
  localStorage.removeItem('role');
  localStorage.removeItem('i18OtherLang');
};

export const onUserLogout = () => {
  handleClearSelectedUserData();
  handleClearLoggedInUserData();
};

export const getDecryptedToken = (tokenKey) => {
  const token = localStorage.getItem(tokenKey);
  var base64Url = token?.split('.')[1];
  var base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  if (base64) {
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }
};

export const getToken = (isAdminApi) => {
  const role = localStorage.getItem('role');
  if (role.toLocaleLowerCase() == 'admin' && isAdminApi == false) {
    const getAdminToken = localStorage.getItem('company_token');
    return getAdminToken;
  } else {
    return localStorage.getItem('token');
  }
};

export const getPascalCaseText = (text) => startCase(camelCase(text.replace(/_/g, ' ')));

export const httpRequest = async (endpoint, body, method = POST, isUsedCompanyToken = false, customBaseUrl = BASE_URL) => {
  const token = getToken(isUsedCompanyToken);
  const API_URL = `${customBaseUrl}${endpoint}`;
  try {
    const res = await fetch(API_URL, {
      method,
      headers: {
        // If the body is FormData, do not set Content-Type header this for multipart/formData
        ...(body instanceof FormData ? {} : { 'Content-Type': 'application/json' }),
        Authorization: `Bearer ${token}`
      },
      body
    });
    const response = await res.json();
    if (!res.ok) {
      // console.log('res', res);
      if (response?.code) {
        openErrorMessage({ message: JSON.stringify(response?.msg?.error) });
        return { response: response, error: JSON.stringify(response?.msg?.error) };
      }
      openErrorMessage({ message: 'Network response was not ok' });
      return { response: response, error: 'Network response was not ok' };
    }
    // console.log('response???', response);
    return { response, error: '' };
  } catch (error) {
    openErrorMessage({ message: 'Error: ' + error.message });
    console.error('Error:', error);
    return { response: '', error };
  }
};

export const getUpsertApiURL = (upsertId, companyId) =>
  upsertId ? `${GATEWAY_ENDPOINT_PREFIX}/${companyId}${EDIT_DATA_URL}` : `${GATEWAY_ENDPOINT_PREFIX}/${companyId}${ADD_DATA_URL}`;

export const getServiceApiURL = (companyId, isRestBIUrl = false) =>
  `${GATEWAY_ENDPOINT_PREFIX}/${companyId}${isRestBIUrl ? GET_RESTBI_SELECT_DATA_URL : GET_SELECT_DATA_URL}`;

export const getDeleteApiURL = (companyId) =>
  companyId ? `${GATEWAY_ENDPOINT_PREFIX}/${companyId}${DELETE_DATA_URL}` : `${DELETE_DATA_URL}`;

export const getJSON = (str) => {
  if (typeof str !== 'string') {
    return false;
  }
  try {
    return JSON.parse(str);
  } catch (error) {
    return false;
  }
};

export const hasPermission = (authPermissionType = [], checkPermissionType = [], isReturnTypeBoolean = true) => {
  if (isEmpty(authPermissionType) || isEmpty(checkPermissionType)) return isReturnTypeBoolean ? false : [];
  const permissionList = authPermissionType.filter((el) => checkPermissionType.includes(el));
  return isReturnTypeBoolean ? !isEmpty(permissionList) : permissionList;
};

export const getGoogleTranslatorData = async (text, srcLang = 'auto', targetLang = 'en') => {
  try {
    const tKey = localStorage.getItem('gtck');
    if (!tKey) {
      openErrorMessage({ message: 'API key is missing' });
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json+protobuf',
        'X-Goog-Api-Key': tKey // Use the API key directly
      },
      body: JSON.stringify([[[text], srcLang, targetLang], 'te'])
    };

    const response = await fetch('https://translate-pa.googleapis.com/v1/translateHtml', requestOptions);

    if (!response.ok) {
      openErrorMessage({ message: `HTTP error! status: ${response.status}` });
    }

    const res = await response.json();

    if (!res || !Array.isArray(res) || !res[0] || !Array.isArray(res[0]) || !res[0][0]) {
      openErrorMessage({ message: 'Unexpected response format' });
    }

    const textarea = document.createElement('textarea');
    textarea.innerHTML = res[0][0];
    const textContent = textarea.textContent;

    return textContent;
  } catch (error) {
    console.error('Error in googleTranslator:', error);
    openErrorMessage({ message: error });
  }
};

export const getValidData = (data, returnData = true) => {
  // nil check for undefine and null and empty check for object array and string
  const isValid = !isNil(data) && !isEmpty(data);
  if (isValid && returnData) {
    return data;
  }
};

export const getFileFromUrl = async (URL) => {
  try {
    const response = await fetch(URL);
    if (!response.ok) {
      throw new Error('Failed to fetch the image from S3');
    }
    const blob = await response.blob();
    const urlParts = URL.split('/');
    const filename = urlParts[urlParts.length - 1];

    const file = new File([blob], filename, { type: blob.type });

    // console.log('Image downloaded and converted:', file);
    return file;
  } catch (error) {
    console.error('Error downloading and converting image:', error);
  }
};
