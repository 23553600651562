/** User login related routs */
export const LOGIN = `login`;
export const HOME = `home`;
export const FORGOT_PASSWORD = `forgot-password`;
export const RESET_PASSWORD = `reset-password`;
export const CHANGE_PASSWORD = `change-password`;
export const PROFILE_UPSERT = `profile`;

export const CHART_CONFIG = `chart-config`;
export const CHART_CONFIG_UPSERT = `${CHART_CONFIG}/upsert`;

export const CHART_INSIGHT = `chart-insights`;
export const CHART_INSIGHT_UPSERT = `${CHART_INSIGHT}/upsert`;

export const KPI_INSIGHT = `kpi-insights`;
export const KPI_INSIGHT_UPSERT = `${KPI_INSIGHT}/upsert`;

export const TARGET_SALES = `target-sales`;
export const TARGET_SALES_UPSERT = `${TARGET_SALES}/upsert`;
export const SALES_CONFIG = `sales-config`;
export const SALES_CONFIG_UPSERT = `${SALES_CONFIG}/upsert`;

export const TENANT_SETTINGS = 'tenant-settings';
export const TENANT_SETTINGS_UPSERT = `${TENANT_SETTINGS}/upsert`;

export const CUSTOM_CHARTS = 'custom-charts';
export const CUSTOM_CHARTS_UPSERT = `${CUSTOM_CHARTS}/upsert`;

export const SQL_FEEDBACK = 'sql-feedbacks';
export const SQL_FEEDBACK_UPSERT = `${SQL_FEEDBACK}/upsert`;

export const COMPANY = 'company';
export const COMPANY_UPSERT = `${COMPANY}/upsert`;

export const COMPANY_HIERARCHY = 'company-hierarchy';
export const COMPANY_HIERARCHY_UPSERT = `${COMPANY_HIERARCHY}/upsert`;

export const DATASET_QUERY_MAP = 'dataset-query-map';
export const DATASET_QUERY_MAP_UPSERT = `${DATASET_QUERY_MAP}/upsert`;

export const ROLE_DASHBOARD_MAP = 'role-dashboard-map';
export const ROLE_DASHBOARD_MAP_UPSERT = `${ROLE_DASHBOARD_MAP}/upsert`;

export const SCHEMA_MAP = 'schema-map';
export const SCHEMA_MAP_UPSERT = `${SCHEMA_MAP}/upsert`;

export const USER = 'user';
export const USER_UPSERT = `${USER}/upsert`;

export const TEMPLATE = 'template';
export const TEMPLATE_UPSERT = `${TEMPLATE}/upsert`;

export const DATASET_QUESTION = 'dataset-question';
export const DATASET_QUESTION_UPSERT = `${DATASET_QUESTION}/upsert`;

export const KPI_CONFIG_DATASET = 'kpi-config-dataset';
export const KPI_CONFIG_DATASET_UPSERT = `${KPI_CONFIG_DATASET}/upsert`;

export const KPI_CONFIG_DIMENSION = 'kpi-config-dimension';
export const KPI_CONFIG_DIMENSION_UPSERT = `${KPI_CONFIG_DIMENSION}/upsert`;

export const KPI_CONFIG_METRIC = 'kpi-config-metric';
export const KPI_CONFIG_METRIC_UPSERT = `${KPI_CONFIG_METRIC}/upsert`;

export const KPI_CONFIG_TIMEFIELD = 'kpi-config-timefield';
export const KPI_CONFIG_TIMEFIELD_UPSERT = `${KPI_CONFIG_TIMEFIELD}/upsert`;

export const ROLE_PERMISSION = 'role-permission';
export const ROLE_PERMISSION_UPSERT = `${ROLE_PERMISSION}/upsert`;

export const RAG_SAMPLE = 'rag-samples';
export const RAG_SAMPLE_UPSERT = `${RAG_SAMPLE}/upsert`;

export const FEATURE_FLAG = 'feature-flags';
export const FEATURE_FLAG_UPSERT = `${FEATURE_FLAG}/upsert`;

export const SUGGESTIVE_INSIGHTS = 'suggestive-insights';
export const SUGGESTIVE_INSIGHTS_UPSERT = `${SUGGESTIVE_INSIGHTS}/upsert`;

export const CHART_DATASET_MAP = 'chart-dataset-map';
export const CHART_DATASET_MAP_UPSERT = `${CHART_DATASET_MAP}/upsert`;

export const EVENTS = 'events';
export const EVENTS_UPSERT = `${EVENTS}/upsert`;
