// CHART CONFIG API PAYLOAD

export const getChartConfigPayload = {
  fields: [
    'c.id',
    'c.dashboard_id',
    'c.dashboard_name',
    'c.chart_id',
    'c.chart_name',
    'c.config_type',
    'c.config_name',
    'c.config_value',
    'c.config_description',
    'c.priority',
    'c.updated_at',
    'c.created_at',
    'c.status',
    'c.display_text',
    'c.validation'
  ],
  table: 'chart_configs c',
  sort: ['c.id DESC']
};

export const getRoleDashboardMapPayload = (companyId, role) =>
  JSON.stringify({
    fields: ['dashboard_id'],
    table: 'role_dashboard_map',
    where: 'company_id = :companyId and user_role = :role and status = true',
    bind: { companyId, role },
    sort: ['id asc'],
    limit: 1000,
    offset: 0
  });

export const getDashboardsPayload = (dashboardIds) =>
  JSON.stringify({
    fields: ['id', 'dashboard_title'],
    table: 'dashboards',
    where: `id IN (${dashboardIds.join(',')})`,
    sort: ['id asc'],
    limit: 1000,
    offset: 0
  });

export const getChartDataPayload = (dashboardID) =>
  JSON.stringify({
    fields: ['dbsl.dashboard_id', 'sl.id', 'sl.slice_name', 'sl.params'],
    table: 'slices sl',
    inner: [{ table: 'dashboard_slices dbsl', relation: 'sl.id = dbsl.slice_id' }],
    where: 'dbsl.dashboard_id = :dashboardID',
    bind: { dashboardID },
    limit: 30,
    offset: 0
  });

export const getMetricDataPayload = (dropdownValue) =>
  JSON.stringify({
    fields: ['tbl_cl.id', 'tbl_cl.table_id', 'tbl.table_name', 'tbl_cl.column_name'],
    table: 'tables tbl',
    inner: [
      { table: 'table_columns tbl_cl', relation: 'tbl_cl.table_id = tbl.id' },
      { table: 'slices sl', relation: "sl.perm LIKE CONCAT('%id:', tbl_cl.table_id, '%')" }
    ],
    where: "tbl.table_name LIKE :getChartName AND tbl_cl.is_active = true AND tbl_cl.type = 'DECIMAL'",
    bind: { getChartName: dropdownValue?.slice_name + '%' }
  });

export const getDataByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: [
      'id',
      'dashboard_id',
      'dashboard_name',
      'chart_id',
      'chart_name',
      'config_type',
      'config_name',
      'config_value',
      'config_description',
      'created_at',
      'updated_at',
      'status',
      'display_text',
      'priority'
    ],
    table: 'chart_configs',
    where: 'id = :id',
    sort: ['id DESC'],
    bind: { id: upsertId },
    limit: 20,
    offset: 0
  });

// CHART INSIGHTS API PAYLOAD

export const getChartInsightsListPayload = {
  fields: [
    'ic.id',
    'ic.dashboard_id',
    'ic.dashboard_name',
    'ic.chart_id',
    'ic.chart_name',
    'ic.config_type',
    'ic.config_name',
    'ic.config_value',
    'ic.config_description',
    'ic.created_at',
    'ic.updated_at',
    'ic.display_order',
    'ic.kpi',
    'ic.group_code',
    'ic.time_grain',
    'ic.status'
  ],
  table: 'insight_configs ic',
  sort: ['ic.id DESC']
};

export const getChartInsightTokenPayload = (companyId, role) =>
  JSON.stringify({
    fields: ['dashboard_id'],
    table: 'role_dashboard_map',
    where: 'company_id = :companyId and user_role = :role and status = true',
    bind: { companyId, role },
    sort: ['id asc'],
    limit: 1000,
    offset: 0
  });

export const getChartInsightResultPayload = (dashboardIds) =>
  JSON.stringify({
    fields: ['id', 'dashboard_title'],
    table: 'dashboards',
    where: `id IN (${dashboardIds.join(',')})`,
    sort: ['id asc'],
    limit: 1000,
    offset: 0
  });

export const getChartInsightsDataPayload = (dashboardID) =>
  JSON.stringify({
    fields: ['dbsl.dashboard_id', 'sl.id', 'sl.slice_name', 'sl.params'],
    table: 'slices sl',
    inner: [{ table: 'dashboard_slices dbsl', relation: 'sl.id = dbsl.slice_id' }],
    where: 'dbsl.dashboard_id = :dashboardID',
    bind: { dashboardID },
    limit: 10,
    offset: 0
  });

export const getChartInsightsMetricPayload = (dropdownValue) =>
  JSON.stringify({
    fields: ['tbl_cl.id', 'tbl_cl.table_id', 'tbl.table_name', 'tbl_cl.column_name'],
    table: 'tables tbl',
    inner: [
      { table: 'table_columns tbl_cl', relation: 'tbl_cl.table_id = tbl.id' },
      { table: 'slices sl', relation: "sl.perm LIKE CONCAT('%id:', tbl_cl.table_id, '%')" }
    ],
    where: "tbl.table_name LIKE :getChartName AND tbl_cl.is_active = true AND tbl_cl.type = 'DECIMAL'",
    bind: { getChartName: dropdownValue?.slice_name + '%' }
  });

export const getChartInsightsMsgPayload = () =>
  JSON.stringify({
    fields: ['s.name', 's.value'],
    table: 'settings s',
    where: 'name IN (:name)',
    bind: { name: ['DEFAULT_INSIGHTS_MSG'] },
    limit: 1,
    offset: 0
  });

// KPI INSIGHT CONFIG API PAYLOAD

export const getKPIConfigPayload = {
  fields: [
    'c.id',
    'd.id as kpi_dataset_id',
    'd.dataset_name',
    'di.id as kpi_dimension_id',
    'di.kpi_dimension_name',
    'm.id as kpi_metric_id',
    'm.kpi_metric_name',
    't.id as kpi_timefield_id',
    't.kpi_timefield_value',
    'c.metric1_value1',
    'c.metric1_value2',
    'c.insight_msg',
    'c.chart_name',
    'c.dashboard_name',
    'c.time_grain',
    'c.status'
  ],
  table: 'insight_kpi_configs c',
  inner: [
    { table: 'kpi_config_datasets d', relation: 'd.id = c.kpi_dataset_id' },
    { table: 'kpi_config_metrics m', relation: 'm.id = c.kpi_metric_id1' }
  ],
  left: [
    { table: 'kpi_config_dimensions di', relation: 'di.id = c.kpi_dimension_id' },
    { table: 'kpi_config_timefields t', relation: 't.id = c.kpi_config_timefield_id' }
  ],
  sort: ['c.id DESC']
};

export const getKPIDimensionsListPayload = (id) =>
  JSON.stringify({
    fields: ['di.kpi_dataset_id', 'di.id as kpi_dimension_id', 'di.kpi_dimension_name'],
    table: 'kpi_config_datasets d',
    inner: [{ table: 'kpi_config_dimensions di', relation: 'di.kpi_dataset_id = d.id' }],
    where: `d.id = ${id}`,
    bind: { datasetId: 1 },
    sort: ['di.kpi_dimension_name asc']
  });

export const getKPITimeFieldListPayload = (id) =>
  JSON.stringify({
    fields: ['t.kpi_dataset_id', 't.id as kpi_timefield_id', 't.kpi_timefield_name'],
    table: 'kpi_config_datasets d',
    inner: [{ table: 'kpi_config_timefields t', relation: 't.kpi_dataset_id = d.id' }],
    where: `d.id = ${id}`,
    bind: { datasetId: 1 },
    sort: ['t.kpi_timefield_name asc']
  });

export const getKPIMetricsListPayload = (id) =>
  JSON.stringify({
    fields: ['m.kpi_dataset_id', 'm.id as kpi_metric_id', 'm.kpi_metric_name', 'm.data_type'],
    table: 'kpi_config_datasets d',
    inner: [{ table: 'kpi_config_metrics m', relation: 'm.kpi_dataset_id = d.id' }],
    where: `d.id = ${id}`,
    bind: { datasetId: 1 },
    sort: ['m.kpi_metric_name asc']
  });

export const getKPIDatasetListPayload = (state) =>
  JSON.stringify({
    fields: ['d.id', 'd.dataset_name'],
    table: 'kpi_config_datasets d',
    sort: ['d.id desc'],
    limit: 30,
    offset: 0
  });

export const getKPIConfigDetailPayload = (kpiConfigId) =>
  JSON.stringify({
    fields: [
      'c.id',
      'c.dashboard_id',
      'c.chart_id',
      'c.insight_msg',
      'd.id as kpi_dataset_id',
      'd.dataset_name',
      'di.id as kpi_dimension_id',
      'di.kpi_dimension_name',
      'm.id as kpi_metric_id',
      'm.kpi_metric_name',
      't.id as kpi_timefield_id',
      't.kpi_timefield_value',
      'c.metric1_value1',
      'c.metric1_value2',
      'c.chart_name',
      'c.dashboard_name',
      'c.external_factors',
      'c.reverse_intent',
      'c.metrics_condition',
      'c.data_condition',
      'c.yearly_comparison',
      'c.time_grain',
      'c.suggestive_insights_id',
      'c.description',
      'c.criteria_text',
      'c.is_suggestive_insights',
      'c.status'
    ],
    table: 'insight_kpi_configs c',
    inner: [
      { table: 'kpi_config_datasets d', relation: 'd.id = c.kpi_dataset_id' },
      { table: 'kpi_config_metrics m', relation: 'm.id = c.kpi_metric_id1' }
    ],
    left: [
      { table: 'kpi_config_dimensions di', relation: 'di.id = c.kpi_dimension_id' },
      { table: 'kpi_config_timefields t', relation: 't.id = c.kpi_config_timefield_id' }
    ],
    where: 'c.id = :configId',
    bind: { configId: kpiConfigId }
  });

export const getKPIDashboardListPayload = (companyId, role) =>
  JSON.stringify({
    fields: ['dashboard_id'],
    table: 'role_dashboard_map',
    where: 'company_id = :companyId and user_role = :role and status = true',
    bind: { companyId, role },
    sort: ['id asc'],
    limit: 1000,
    offset: 0
  });

export const getKPIMessagePayload = () =>
  JSON.stringify({
    fields: ['s.name', 's.value'],
    table: 'settings s',
    where: 'name IN (:name)',
    bind: { name: ['DEFAULT_INSIGHTS_MSG'] },
    limit: 1,
    offset: 0
  });

// TARGET CONFIG API PAYLOAD

export const getTargetDataPaginationPayload = {
  fields: [
    'sc.id',
    'sc.company_id',
    'c.company_name',
    'sc.franchise_id',
    'f.franchise_name',
    'sc.location_id',
    'l.location_name',
    'sc.year',
    'sc.product_category_id',
    'pc.product_category_name',
    'sc.product_id',
    'p.product_name',
    'sc.employee_id',
    'e.first_name',
    'e.last_name'
  ],
  table: 'sales_configs sc',
  inner: [{ table: 'companies c', relation: 'c.id = sc.company_id' }],
  left: [
    { table: 'franchises f', relation: 'f.id = sc.franchise_id' },
    { table: 'locations l', relation: 'l.id = sc.location_id' },
    { table: 'product_categories pc', relation: 'pc.id = sc.product_category_id' },
    { table: 'products p', relation: 'p.id = sc.product_id' },
    { table: 'employees e', relation: 'e.id = sc.employee_id' }
  ],
  sort: ['sc.id desc']
};

export const getSaleConfigListPayload = (saleConfigId) =>
  JSON.stringify({
    fields: [
      'sc.id as sales_config_id',
      'scv.id as sales_config_value_id',
      'sc.company_id',
      'c.company_name',
      'sc.franchise_id',
      'f.franchise_name',
      'sc.location_id',
      'l.location_name',
      'sc.year',
      'sc.product_category_id',
      'pc.product_category_name',
      'sc.product_id',
      'p.product_name',
      'sc.employee_id',
      'e.first_name',
      'e.last_name',
      'scv.target_period_type',
      'scv.target_period_value',
      'scv.target_sales',
      'scv.target_sales_percent_vary',
      'scv.target_leads',
      'scv.target_leads_percent_vary'
    ],
    table: 'sales_configs sc',
    inner: [{ table: 'companies c', relation: 'c.id = sc.company_id' }],
    left: [
      { table: 'franchises f', relation: 'f.id = sc.franchise_id' },
      { table: 'locations l', relation: 'l.id = sc.location_id' },
      { table: 'product_categories pc', relation: 'pc.id = sc.product_category_id' },
      { table: 'products p', relation: 'p.id = sc.product_id' },
      { table: 'employees e', relation: 'e.id = sc.employee_id' },
      { table: 'sales_config_values scv', relation: 'scv.sales_config_id = sc.id' }
    ],
    where: 'sc.id = :salesConfigId',
    bind: { salesConfigId: saleConfigId },
    sort: ['sc.year desc', 'scv.target_period_value asc'],
    limit: 12,
    offset: 0
  });

export const getListOfCompanyPayload = () =>
  JSON.stringify({
    fields: ['id', 'company_name'],
    table: 'companies',
    sort: ['company_name asc']
  });

export const getListOfFranchisePayload = (comp_id) =>
  JSON.stringify({
    fields: ['id', 'franchise_name'],
    table: 'franchises',
    sort: ['franchise_name asc'],
    where: 'company_id = :companyId',
    bind: { companyId: comp_id }
  });

export const getFranchiseLocationPayload = (comp_id) =>
  JSON.stringify({
    fields: ['id', 'location_name'],
    table: 'locations',
    sort: ['location_name asc'],
    where: 'franchise_id = :franchiseId',
    bind: { franchiseId: comp_id }
  });

export const getEmployLocationPayload = (location_id) =>
  JSON.stringify({
    fields: ['id', 'first_name', 'last_name', 'employee_code'],
    table: 'employees',
    sort: ['first_name asc', 'last_name asc'],
    where: 'location_id = :locationId',
    bind: { locationId: location_id }
  });

export const getProductCategoryPayload = () =>
  JSON.stringify({
    fields: ['id', 'product_category_name'],
    table: 'product_categories',
    sort: ['product_category_name asc']
  });

export const getProductBasedOnCategoryPayload = (productCategoryId) =>
  JSON.stringify({
    fields: ['id', 'product_name'],
    table: 'products',
    sort: ['product_name asc'],
    where: 'product_category_id = :productCategoryId',
    bind: { productCategoryId }
  });

export const getTenantSettingPayload = {
  fields: ['s.id', 's.name', 's.value', 's.status'],
  table: 'settings s',
  sort: ['id DESC']
};

// COMMON API PAYLOAD

export const getUserRoleDataPayload = () =>
  JSON.stringify({
    fields: ['u.id', 'u.name', 'u.email', 'u.company_id', 'c.name as company'],
    table: '"user" u',
    inner: [{ table: 'company c', relation: 'c.id = u.company_id' }],
    where: "u.status = true and role != 'Admin'",
    limit: 1000,
    offset: 0
  });

export const getProfileDataPayload = (userId) =>
  JSON.stringify({
    fields: [
      'id',
      'company_id',
      'email',
      'name',
      'hierarchy_id',
      'department',
      'role',
      'employee_no',
      'pic',
      'hierarchy_key_value',
      'otp',
      'home_metadata',
      'status',
      'password',
      'lang'
    ],
    table: '"user"',
    where: 'id = :userId and status = true',
    bind: { userId }
  });

export const getfetchIdDataPayload = () =>
  JSON.stringify({
    fields: ['ic.id'],
    table: 'insight_configs ic',
    sort: ['ic.id DESC'],
    limit: 1,
    offset: 0
  });

export const getFetchDataByEditIdPayload = (upsertId) =>
  JSON.stringify({
    fields: [
      'ic.id',
      'ic.dashboard_id',
      'ic.dashboard_name',
      'ic.chart_id',
      'ic.chart_name',
      'ic.config_type',
      'ic.config_name',
      'ic.config_value',
      'ic.config_description',
      'ic.created_at',
      'ic.updated_at',
      'ic.display_order',
      'ic.kpi',
      'ic.group_code',
      'ic.status',
      'ic.insight_msg',
      'ic.external_factors',
      'ic.reverse_intent',
      'ic.metric_condition',
      'ic.data_condition',
      'ic.yearly_comparison',
      'ic.time_grain',
      'ic.status'
    ],
    table: 'insight_configs ic',
    where: 'ic.id = :insightId',
    sort: ['ic.id DESC'],
    bind: { insightId: upsertId },
    limit: 20,
    offset: 0
  });

export const getSettingsFetchDataByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['s.name', 's.value'],
    table: 'settings s',
    where: 'id = :id',
    sort: ['id DESC'],
    bind: { id: upsertId },
    limit: 20,
    offset: 0
  });

export const getCustomChartPaginationPayload = {
  fields: [
    'c.id',
    'c.dashboard_id',
    'c.api_endpoint',
    'c.api_payload',
    'c.chart_name',
    'c.chart_library_name',
    'c.cdn_urls',
    'c.chart_type',
    'c.template_code',
    'c.api_headers',
    'c.status'
  ],
  table: 'custom_charts c',
  sort: ['id DESC'],
  limit: 100,
  offset: 0
};

export const getCustomChartByIdPayload = (id) =>
  JSON.stringify({
    fields: [
      'c.id',
      'c.dashboard_id',
      'c.api_endpoint',
      'c.api_payload',
      'c.chart_name',
      'c.chart_library_name',
      'c.cdn_urls',
      'c.chart_type',
      'c.template_code',
      'c.api_headers',
      'c.status',
      'c.no_of_metrics'
    ],
    table: 'custom_charts c',
    where: 'id = :getID',
    bind: { getID: id }
  });

export const getSQLFeedbackPaginationPayload = (isPackemPOrSelectedUser) => ({
  fields: [
    'sf.id',
    'sf.dataset_name',
    'sf.question',
    'sf.answer',
    'sf.gen_sql',
    'sf.rec_sql',
    'sf.description',
    'sf.rejected',
    'sf.message',
    'sf.status',
    'sf.is_internal',
    'sf.is_positive'
  ],
  table: 'sql_feedbacks sf',
  ...(isPackemPOrSelectedUser && {
    where: 'is_internal = false'
  }),
  sort: ['sf.id desc']
});

export const getSqlFeedbackByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: [
      'sf.id',
      'sf.dataset_name',
      'sf.question',
      'sf.answer',
      'sf.gen_sql',
      'sf.rec_sql',
      'sf.description',
      'sf.rejected',
      'sf.message',
      'sf.status'
    ],
    table: 'sql_feedbacks sf',
    where: 'sf.id = :getID',
    bind: { getID: upsertId }
  });

export const getGeneratedInsightsPayload = (id, userId, fromDate, toDate, dashboardId) =>
  JSON.stringify({
    fields: [
      'DISTINCT ih.id as ins_id',
      'ih.dashboard_id',
      'ih.chart_id',
      'ih.insight_msg',
      'ih.from_date',
      'ih.to_date',
      'ih.alert_type',
      ':userId as usr_id',
      'CASE WHEN ir.user_id != :userId OR ir.id IS NULL THEN 0 ELSE 1 END as is_read',
      'ih.insights_data'
    ],
    table: 'insights_history ih',
    left: [{ table: 'insight_reads ir', relation: 'ir.insight_history_id = ih.id AND ir.user_id = :userId' }],
    where:
      'insight_config_id = :insightConfigId AND ((ih.from_date >= :fromDate AND ih.to_date <= :toDate) OR (ih.from_date IS NULL AND ih.created_at >= :fromDate AND ih.created_at <= :toDate)) AND ih.insight_msg ilike :insightMsg AND ih.dashboard_id IN (:dashboardIds)',
    sort: ['ih.from_date DESC'],
    bind: {
      userId,
      fromDate,
      toDate,
      insightMsg: '%%',
      dashboardIds: [dashboardId],
      insightConfigId: id
    },
    limit: 100,
    offset: 0
  });

export const getViewGeneratedInsightsPayload = (id, fromDate, toDate) =>
  JSON.stringify({
    fields: [
      'DISTINCT ih.id as ins_id',
      'ih.dashboard_id',
      'ih.chart_id',
      'ih.insight_msg',
      'ih.from_date',
      'ih.to_date',
      'ih.insights_data'
    ],
    table: 'insights_history ih',
    where: 'insight_config_id = :insightConfigId AND from_date = :fromDate AND to_date = :toDate',
    sort: ['ih.from_date DESC'],
    bind: {
      fromDate,
      toDate,
      insightConfigId: id
    },
    limit: 100,
    offset: 0
  });

export const getInsightsPayload = (insightHistoryID, dataSourceType) =>
  JSON.stringify({
    fields: ['ihd.id', 'ihd.insight_history_id', 'ihd.datasource_type', 'ihd.insights_data'],
    table: 'insights_history_data ihd',
    where: 'ihd.insight_history_id = :insightHistoryID AND ihd.datasource_type = :dataSourceType',
    bind: { insightHistoryID, dataSourceType }
  });

export const getInsightsActionsPayload = (insightHistoryID) =>
  JSON.stringify({
    fields: ['ah.id as act_id', 'ah.action_msg', 'ah.from_date', 'ah.to_date', 'ah.alert_type', 'ah.created_at'],
    table: 'actions_history ah',
    where: 'ah.insight_history_id = :insightHistoryId',
    sort: ['ah.from_date DESC'],
    bind: { insightHistoryId: insightHistoryID },
    limit: 20,
    offset: 0
  });

export const getInsightsActionsMarksPayload = (insightHistoryID) =>
  JSON.stringify({
    fields: ['id', 'is_marked', 'user_id', 'action_history_id', 'created_at', 'updated_at'],
    table: 'action_marks am',
    where: 'am.action_history_id = :actionHistoryId',
    bind: { actionHistoryId: insightHistoryID }
  });

export const getInsightsReadPayload = (insightHistoryID) =>
  JSON.stringify({
    fields: ['id', 'is_read', 'user_id', 'insight_history_id', 'created_at', 'updated_at'],
    table: 'insight_reads ir',
    where: 'ir.insight_history_id = :insightHistoryId',
    bind: { insightHistoryId: insightHistoryID }
  });

export const getSettingsByNamePayload = (name) =>
  JSON.stringify({
    fields: ['s.name', 's.value'],
    table: 'settings s',
    where: 'name IN (:name)',
    bind: { name }
  });

export const getCompanyPaginationPayload = {
  fields: ['id', 'name', 'hierachy', 'domain', 'logo', 'status'],
  table: 'company',
  sort: ['id desc'],
  limit: 10,
  offset: 0
};

export const getCompanyByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['name', 'hierachy', 'domain', 'logo', 'status'],
    table: 'company',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getCompanyListPayload = () =>
  JSON.stringify({
    fields: ['id', 'name'],
    table: 'company',
    sort: ['id']
  });

export const getCompanyHierarchyPaginationPayload = (companyId) => ({
  fields: [
    'ch.id',
    'ch.company_id',
    'c.name as company_name',
    'ch.name as hierarchy_name',
    'ch.level',
    'ch.parent_id',
    'ch.hierarchy_key_name',
    'ch.status'
  ],
  table: 'company_hierarchy ch',
  inner: [{ table: 'company c', relation: 'c.id = ch.company_id' }],
  sort: ['id desc'],
  where: 'ch.company_id = :companyId and ch.status = true',
  bind: { companyId },
  limit: 10,
  offset: 0
});

export const getCompanyHierarchyByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['id', 'company_id', 'name', 'level', 'hierarchy_key_name', 'status'],
    table: 'company_hierarchy',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getCompanyHierarchyListPayload = (companyId) =>
  JSON.stringify({
    fields: ['id', 'name', 'level'],
    table: 'company_hierarchy',
    where: 'company_id = :companyId',
    bind: { companyId }
  });

export const getDatasetQueryMapPaginationPayload = (companyId) => ({
  fields: ['d.id', 'd.company_id', 'c.name as company_name', 'd.title', 'd.tables', 'd.user_role', 'd.status'],
  table: 'dataset_query_map d',
  where: 'd.company_id = :companyId and d.status = true',
  inner: [{ table: 'company c', relation: 'c.id = d.company_id' }],
  sort: ['id desc'],
  bind: { companyId },
  limit: 10,
  offset: 0
});

export const getDatasetQueryMapByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['company_id', 'title', 'tables', 'user_role', 'status'],
    table: 'dataset_query_map',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getDatasetQueryMapListPayload = (companyId, role) => {
  return JSON.stringify({
    fields: ['id, title'],
    table: 'dataset_query_map',
    where: 'company_id=:companyId and user_role = :role and status = true',
    sort: ['id desc'],
    bind: { companyId, role }
  });
};

export const getRoleDashboardMapPaginationPayload = (companyId) => ({
  fields: ['r.id', 'r.user_role', 'r.dashboard_id', 'r.company_id', 'c.name as company_name', 'r.custom_apis', 'r.nav_links', 'r.status'],
  table: 'role_dashboard_map r',
  where: 'r.company_id = :companyId and r.status = true',
  inner: [{ table: 'company c', relation: 'c.id = r.company_id' }],
  sort: ['id desc'],
  bind: { companyId },
  limit: 10,
  offset: 0
});

export const getRoleDashboardMapByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['user_role', 'dashboard_id', 'company_id', 'custom_apis', 'nav_links', 'status'],
    table: 'role_dashboard_map',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getSchemaMapPaginationPayload = (companyId) => ({
  fields: ['id', 'company_id', 'path', 'uri', 'db_host', 'unp', 'uri_prefix', 'status'],
  table: 'schema_map',
  sort: ['id desc'],
  where: 's.company_id = :companyId and s.status = true',
  bind: { companyId },
  limit: 10,
  offset: 0
});

export const getSchemaMapByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['company_id', 'path', 'uri', 'db_host', 'unp', 'uri_prefix', 'status'],
    table: 'schema_map',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getUserPaginationPayload = (companyId) => ({
  fields: [
    'u.id',
    'u.company_id',
    'u.email',
    'u.name',
    'u.hierarchy_id',
    'u.department',
    'u.role',
    'u.employee_no',
    'u.pic',
    'u.hierarchy_key_value',
    'u.otp',
    'u.home_metadata',
    'u.status',
    'u.lang',
    'ch.name as user_hierarchy_name'
  ],
  table: '"user" u',
  where: 'u.company_id = :companyId and u.status = true',
  inner: [{ table: 'company_hierarchy ch', relation: 'ch.id = u.hierarchy_id' }],
  sort: ['id desc'],
  bind: { companyId },
  limit: 10,
  offset: 0
});

export const getUserByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: [
      'id',
      'company_id',
      'email',
      'name',
      'hierarchy_id',
      'department',
      'role',
      'employee_no',
      'pic',
      'hierarchy_key_value',
      'otp',
      'home_metadata',
      'status',
      'password',
      'lang'
    ],
    table: '"user"',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getDashboardListPayload = (selectedUserRole) => {
  return JSON.stringify({
    fields: ['ab_r.id, d.dashboard_title, d.id'],
    table: 'ab_role ab_r',
    inner: [
      { table: 'dashboard_roles dr', relation: 'ab_r.id = dr.role_id' },
      { table: 'dashboards d', relation: 'dr.dashboard_id = d.id' }
    ],
    where: 'ab_r.name = :user_role',
    bind: { user_role: selectedUserRole }
  });
};

export const getDatasetQuestionPaginationPayload = (companyId, role) => ({
  fields: ['dq.id', 'dqm.title', 'dq.question', 'dq.status'],
  table: 'dataset_questions dq',
  inner: [{ table: 'dataset_query_map dqm', relation: 'dqm.id = dq.dataset_query_map_id' }],
  where: 'dqm.company_id = :companyId and dqm.user_role = :role',
  bind: { companyId, role },
  sort: ['id desc'],
  limit: 10,
  offset: 0
});

export const getDatasetQuestionByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['id', 'dataset_query_map_id', 'question', 'status'],
    table: 'dataset_questions',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getTemplatePaginationPayload = (companyId) => ({
  fields: ['e.id', 'e.company_id', 'c.name as company_name', 'e.name', 'e.description', 'e.template', 'e.variables', 'e.status'],
  table: 'email_template e',
  inner: [{ table: 'company c', relation: 'c.id = e.company_id' }],
  where: 'company_id = :companyId',
  bind: { companyId },
  sort: ['id desc'],
  limit: 10,
  offset: 0
});

export const getTemplateByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['id', 'company_id', 'name', 'description', 'template', 'variables', 'status'],
    table: 'email_template',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getEnglishTextPayload = (text, sl = null, tl = 'en-US') =>
  JSON.stringify({
    text,
    sl,
    tl
  });

export const getKpiConfigDatasetsPaginationPayload = {
  fields: ['id', 'dataset_name', 'dataset_table', 'inner_relations', 'outer_relations', 'status', 'role'],
  sort: ['id DESC']
};

export const getKpiConfigDatasetsByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['id', 'dataset_name', 'dataset_table', 'inner_relations', 'outer_relations', 'status'],
    table: 'kpi_config_datasets',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getDeletePayload = (id, table) =>
  JSON.stringify({
    objects: [
      {
        table,
        where: 'id IN (:id)',
        bind: { id: [id] }
      }
    ]
  });

export const getKpiConfigDimensionsPaginationPayload = {
  fields: ['k.id', 'k.kpi_dimension_name', 'k.kpi_dimension_value', 'k.kpi_dataset_id', 'k.status', 'kd.dataset_name as kpi_dataset_name'],
  sort: ['k.id DESC'],
  inner: [{ table: 'kpi_config_datasets kd', relation: 'kd.id = k.kpi_dataset_id' }]
};

export const getKpiConfigDimensionsByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['id', 'kpi_dimension_name', 'kpi_dimension_value', 'kpi_dataset_id', 'status'],
    table: 'kpi_config_dimensions',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getKpiConfigMetricsPaginationPayload = {
  fields: [
    'k.id',
    'k.kpi_metric_name',
    'k.kpi_metric_value',
    'k.data_type',
    'k.kpi_dataset_id',
    'k.status',
    'kd.dataset_name as kpi_dataset_name'
  ],
  sort: ['k.id DESC'],
  inner: [{ table: 'kpi_config_datasets kd', relation: 'kd.id = k.kpi_dataset_id' }]
};

export const getKpiConfigMetricsByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['id', 'kpi_metric_name', 'kpi_metric_value', 'kpi_dataset_id', 'data_type', 'status'],
    table: 'kpi_config_metrics',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getKpiConfigTimefieldsPaginationPayload = {
  fields: ['k.id', 'k.kpi_timefield_name', 'k.kpi_timefield_value', 'k.kpi_dataset_id', 'k.status', 'kd.dataset_name as kpi_dataset_name'],
  sort: ['k.id DESC'],
  inner: [{ table: 'kpi_config_datasets kd', relation: 'kd.id = k.kpi_dataset_id' }]
};

export const getKpiConfigTimefieldsByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['id', 'kpi_timefield_name', 'kpi_timefield_value', 'kpi_dataset_id', 'status'],
    table: 'kpi_config_timefields',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getAllUsersRolesPayload = (companyId) =>
  JSON.stringify({
    fields: ['DISTINCT u.role, u.company_id'],
    table: '"user" u',
    ...(companyId && {
      where: 'u.company_id = :companyId',
      bind: { companyId }
    })
  });

export const getPermissionPaginationPayload = (userRole) => ({
  fields: [
    'urp.id',
    'urp.company_id',
    'urp.user_role',
    'urp.module_name',
    'urp.can_list',
    'urp.can_view',
    'urp.can_create',
    'urp.can_update',
    'urp.can_delete',
    'urp.created_at',
    'urp.updated_at',
    'urp.status',
    'urp.is_visible_in_sidebar'
  ],
  table: 'role_permissions urp',
  sort: ['urp.id asc'],
  limit: 100,
  offset: 0,
  ...(userRole && {
    where: 'urp.user_role = :userRole',
    bind: { userRole }
  })
});

export const getPermissionReadPayload = (userRole) => JSON.stringify(getPermissionPaginationPayload(userRole));

export const getPermissionByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: [
      'urp.id',
      'urp.company_id',
      'urp.user_role',
      'urp.module_name',
      'urp.can_list',
      'urp.can_view',
      'urp.can_create',
      'urp.can_update',
      'urp.can_delete',
      'urp.created_at',
      'urp.updated_at',
      'urp.status',
      'urp.is_visible_in_sidebar'
    ],
    table: 'role_permissions urp',
    where: 'urp.id = :rolePermissionId',
    bind: { rolePermissionId: upsertId }
  });

export const getCheckUniquePermissionNamePayload = (authCompanyId, moduleName, userRole) =>
  JSON.stringify({
    fields: [
      'urp.id',
      'urp.company_id',
      'urp.user_role',
      'urp.module_name',
      'urp.can_list',
      'urp.can_view',
      'urp.can_create',
      'urp.can_update',
      'urp.can_delete',
      'urp.created_at',
      'urp.updated_at',
      'urp.status'
    ],
    table: 'role_permissions urp',
    where: 'urp.company_id=:authCompanyId and urp.module_name = :moduleName and urp.user_role = :userRole',
    bind: { authCompanyId, moduleName, userRole }
  });

export const getAllModulesListPayload = (userRole) =>
  JSON.stringify({
    fields: [
      'DISTINCT  urp.module_name'
      // 'urp.id'
      // 'urp.company_id',
      // 'urp.user_role',
      // 'urp.can_list',
      // 'urp.can_view',
      // 'urp.can_create',
      // 'urp.can_update',
      // 'urp.can_delete',
      // 'urp.created_at',
      // 'urp.updated_at',
      // 'urp.status',
      // 'urp.is_visible_in_sidebar'
    ],
    table: 'role_permissions urp'
    // sort: ['urp.id asc'],
    // ...(userRole && {
    //   where: 'urp.user_role = :userRole',
    //   bind: { userRole }
    // })
  });

export const getModulesListByRolePayload = (userRole) =>
  JSON.stringify({
    fields: ['DISTINCT  urp.module_name'],
    table: 'role_permissions urp',
    where: "urp.user_role != 'Admin' and urp.module_name NOT IN (SELECT module_name FROM role_permissions where user_role = :userRole)",
    bind: { userRole },
    limit: 100,
    offset: 0
  });

export const getRagTrainingPaginationPayload = {
  fields: ['id', 'question', 'sql_query', 'extra_prompts', 'status', 'tables'],
  sort: ['id DESC'],
  table: 'rag_training'
};

export const getRagTrainingByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['id', 'question', 'sql_query', 'extra_prompts', 'status', 'tables'],
    table: 'rag_training',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getSelectedUserSchemaPayload = (companyId) =>
  JSON.stringify({
    fields: ['company_id', 'path', 'uri', 'status'],
    table: 'schema_map',
    where: 'company_id = :companyId',
    bind: { companyId: companyId }
  });

export const getSelectedUserTablePayload = (datasource) => JSON.stringify({ datasource });

export const getQuestionAnswerPayload = (data) => JSON.stringify(data);

export const getVisualPayload = (data) => JSON.stringify(data);

export const getNewlyCreatedIdPayload = (table, fields = ['id'], sort = ['id DESC'], limit = 1) =>
  JSON.stringify({
    table,
    fields,
    sort,
    limit
  });

export const getNewTenantSettingIDPayload = getNewlyCreatedIdPayload('settings');

export const getNewChartConfigIDPayload = getNewlyCreatedIdPayload('chart_configs');

export const getNewKpiInsightsIDPayload = getNewlyCreatedIdPayload('insight_kpi_configs');

export const getNewSalesConfigIDPayload = getNewlyCreatedIdPayload('sales_config_values');

export const getNewCustomChartsIDPayload = getNewlyCreatedIdPayload('custom_charts');

export const getRagTrainingIDPayload = getNewlyCreatedIdPayload('rag_training');

export const getSqlFeedbackIDPayload = getNewlyCreatedIdPayload('sql_feedbacks');

export const getRolePermissionIDPayload = getNewlyCreatedIdPayload('role_permissions');

export const getCompanyIDPayload = getNewlyCreatedIdPayload('company');

export const getCompanyHierarchyIDPayload = getNewlyCreatedIdPayload('company_hierarchy');

export const getDatasetQueryMapIDPayload = getNewlyCreatedIdPayload('dataset_query_map');

export const getRoleDashboardMapIDPayload = getNewlyCreatedIdPayload('role_dashboard_map');

export const getSchemaMapIDPayload = getNewlyCreatedIdPayload('schema_map');

export const getUserIDPayload = getNewlyCreatedIdPayload('"user"');

export const getTemplateIDPayload = getNewlyCreatedIdPayload('email_template');

export const getDatasetQuestionIDPayload = getNewlyCreatedIdPayload('dataset_questions');

export const getKpiConfigDatasetIDPayload = getNewlyCreatedIdPayload('kpi_config_datasets');

export const getKpiConfigDimensionIDPayload = getNewlyCreatedIdPayload('kpi_config_dimensions');

export const getKpiConfigMetricIDPayload = getNewlyCreatedIdPayload('kpi_config_metrics');

export const getKpiConfigTimeFieldIDPayload = getNewlyCreatedIdPayload('kpi_config_timefields');

export const getChartInsightIDPayload = getNewlyCreatedIdPayload('insight_configs');

export const getDatasetByTablesPayload = (companyId, role, tables) =>
  JSON.stringify({
    fields: ['title'],
    table: 'dataset_query_map',
    where: 'company_id = :companyId and user_role= :role and tables =:tables',
    bind: { companyId, role, tables }
  });

export const getCompanyFeatureFlagPayload = (companyId, status = undefined) => ({
  fields: ['f.id', 'f.company_id', 'c.name as company', 'f.name', 'f.description', 'f.status'],
  table: 'feature_flags f',
  inner: [{ table: 'company c', relation: 'c.id = f.company_id' }],
  ...(status
    ? {
        where: 'f.company_id = :companyId AND f.status = true'
      }
    : {
        where: 'f.company_id = :companyId'
      }),
  bind: { companyId },
  sort: ['id desc']
});
export const getFeatureFlagByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['id', 'name', 'description', 'company_id', 'status'],
    table: 'feature_flags',
    where: 'id = :id',
    sort: ['id DESC'],
    bind: { id: upsertId }
  });

export const getNewFeatureFlagIDPayload = getNewlyCreatedIdPayload('feature_flags');

export const getSuggestiveInsightsPaginationPayload = {
  fields: ['id', 'title', 'description', 'status', 'role'],
  table: 'suggestive_insights',
  sort: ['id desc'],
  limit: 100,
  offset: 0
};

export const getSuggestiveInsightsByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['id', 'title', 'description', 'status', 'role'],
    table: 'suggestive_insights',
    where: 'id = :id',
    sort: ['id DESC'],
    bind: { id: upsertId }
  });

export const getNewSuggestiveInsightsIDPayload = getNewlyCreatedIdPayload('suggestive_insights');

export const getChartDatasetMapPaginationPayload = (companyId) => ({
  fields: ['cd.id', 'cd.chart_name', 'cd.company_id', 'c.name as company', 'cd.role', 'cd.status'],
  where: 'cd.company_id = :companyId',
  inner: [{ table: 'company c', relation: 'c.id = cd.company_id' }],
  bind: { companyId },
  table: 'chart_dataset_map cd',
  sort: ['cd.id asc'],
  limit: 100,
  offset: 0
});

export const getChartDatasetMapByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['id', 'chart_name', 'chart_id', 'sql_query', 'company_id', 'role', 'status'],
    table: 'chart_dataset_map',
    where: 'id = :id',
    sort: ['id DESC'],
    bind: { id: upsertId }
  });

export const getNewChartDatasetMapIDPayload = getNewlyCreatedIdPayload('chart_dataset_map');

export const getEventsPaginationPayload = {
  fields: ['e.id', 'e.type', 'e.name', 'e.description', 'e.location_id', 'l.location_name as location'],
  table: 'events e',
  inner: [{ table: 'locations l', relation: 'l.id = e.location_id' }],
  sort: ['id DESC'],
  limit: 100,
  offset: 0
};

export const getEventsByIdPayload = (upsertId) =>
  JSON.stringify({
    fields: ['id', 'type', 'name', 'description', 'location_id', 'start_date', 'end_date'],
    table: 'events',
    where: 'id = :getID',
    bind: { getID: upsertId }
  });

export const getEventsIDPayload = getNewlyCreatedIdPayload('events');

export const getLocationsPayload = () =>
  JSON.stringify({
    fields: ['id', 'location_name'],
    table: 'locations',
    sort: ['id DESC'],
    limit: 100,
    offset: 0
  });
